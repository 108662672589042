import Resizer from "react-image-file-resizer";
import moment from "moment";

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, //file object
      1500, //max-width of resized image
      1500, //max-height of resized image
      "JPEG", //format to convert to
      80, //quality of image (It's a no. b/w 0 to 100)
      0, //rotation of the image
      (uri) => {
        //func for getting the uri of new object
        resolve(uri);
      },
      "base64"
    );
  });

export const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const checkIfSizeExceeds = (file, maxSize) => {
  return file.size >= maxSize;
};

export const checkImageDimensions = (file) =>
  new Promise((resolve) => {
    const img = new Image();
    img.src = file;
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
  });

export const checkAspectRatio = ({ height, width }) => {
  if (width > height) {
    // landscape
    return true;
  } else if (width < height) {
    // portrait
    return false;
  } else {
    // even
    return false;
  }
};

export const checkImageDate = (file) => {
  var date = moment(file.lastModifiedDate);
  var now = moment();

  // const isSameDay = moment(now).isSame(date, "day");

  const differenceDays = now.diff(date, "days");

  if (differenceDays <= 2) {
    return true;
  } else {
    return false;
  }
};
