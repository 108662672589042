export const CHECK_IN_SERVICES = {
  getUserReservationDetails: {
    key: "reservation",
    apiEndpoint: "getGuestUserReservationDetails",
  },
  getExtraChargesItems: {
    apiEndpoint: "extracharges/getExtraChargesItems",
  },
  getStripePublicToken: {
    key: "stripe",
    apiEndpoint: "stripe/getStripePublicToken",
  },
  getHoldAmountByProperty: {
    key: "stripe",
    apiEndpoint: "stripe/getHoldAmountByProperty",
  },
  getUserUploadedDocuments: {
    apiEndpoint: "reservation/getGuestUserReservationIdentityDocuments",
  },
  createExtraCharge: {
    apiEndpoint: "extracharges/createExtraCharge",
  },

  charge: {
    apiEndpoint: "stripe/charge",
  },
  getReservationData: {
    apiEndpoint: "reservations/getReservationData",
  },
  updatePreCheckIn: {
    apiEndpoint: "reservations/updatePreCheckIn",
  },
  sendNotification: {
    apiEndpoint: "extracharges/sendNotification",
  },
};
