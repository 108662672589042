import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";
import { getPropertyId } from "src/app/common/utils/localStorageUtils";

export const getReservationDetailsService = async (apiEndpoint, params) => {
  const token = await getJwtToken();
  const propertyId = await getPropertyId();
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams({ reservationId: params.reservationId, propertyId })
      .GET()
      .then((response) => {
        let r = response?.data;
        if(response && response.data && typeof (response.data) === 'string'){
          r = (JSON.parse(response.data));
        }
        resolve(r);      })
      .catch((err) => {
        reject(err);
      });
  });
};
