import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
	getHoldAmountByPropertyService,
	getStripePublicTokenService,
	getUserUploadedDocumentService
} from "./service";
import { CHECK_IN_SERVICES } from "./constants";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showPageLoader } from "../Middleware/actionCreators";
import { getPropertyId, getReservationId, getUserId } from "src/app/common/utils/localStorageUtils";
import { MessageButton } from "../../components/MessageButton";
import CheckoutForm from "../../components/CheckoutForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const ELEMENTS_OPTIONS = {
	fonts: [
		{
			cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
		}
	]
};

const HoldPayment = (props) => {
	const [stripePromise, setStripePromise] = useState(null);
	const [holdAmount, setHoldAmount] = useState("250.00");

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: "auto"
		});
		initiate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initiate = async () => {
		props.showPageLoader(true);
		try {
			await getStripePublicToken();
			let holdAmountResponse = await getHoldAmountByProperty();
			setHoldAmount(holdAmountResponse);
			await getUserUploadedDocument();

			await sleep(6000);
		} catch (error) {
			redirectToErrorScreen(error);
		} finally {
			props.showPageLoader(false);
		}
	};
	function sleep(ms) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	}

	const getUserUploadedDocument = async () => {
		const { apiEndpoint } = CHECK_IN_SERVICES.getUserUploadedDocuments;
		try {
			const reservationId = await getReservationId();
			const userId = await getUserId();
			const response = await getUserUploadedDocumentService(apiEndpoint, {
				reservationId,
				userId
			});

			if (
				(response && response.length > 0, response[0].hasOwnProperty("identity_doc_s3_key"))
			) {
				if (!response[0].identity_doc_s3_key || response[0].identity_doc_s3_key === "") {
					props.history.push("/upload-document");
				}
			}
		} catch (error) {
			redirectToErrorScreen(error);
		}
	};

	const redirectToErrorScreen = (error) => {
		props.history.push("/error");
	};

	const getStripePublicToken = async () => {
		const propertyId = await getPropertyId();
		try {
			const response = await getStripePublicTokenService(
				CHECK_IN_SERVICES.getStripePublicToken.apiEndpoint,
				propertyId
			);

			console.log("Stripe Public Token", JSON.stringify(response));

			const stripePromise = loadStripe(response.stripe_public_token);

			// const stripeToken = loadStripe("pk_live_51HerBBKkwkO0hXcYwR1K6jz1PYNzqFn3TNqxP6gmTUhcXrhnfwyI9uiOPcSI719SZQMLHwc76dJ11SGLgcIvxUOJ004SMml3gA");

			setStripePromise(stripePromise);
		} catch (error) {
			redirectToErrorScreen(error);
		}
	};

	const getHoldAmountByProperty = async () => {
		const propertyId = await getPropertyId();
		try {
			const holdAmount = await getHoldAmountByPropertyService(
				CHECK_IN_SERVICES.getHoldAmountByProperty.apiEndpoint,
				propertyId
			);

			setHoldAmount(holdAmount.property.toFixed(2));
			return holdAmount.property.toFixed(2).toString();
		} catch (error) {
			redirectToErrorScreen(error);
		}
	};

	return (
		<div className="checkin-screen aligning-middle">
			<div className="checking-details">
				<div className="checkin-hold">
					<div className="check-titles" style={{ padding: "5px", fontSize: "16px" }}>
						Enter your Credit Card Information
					</div>

					<div
						className="check-titles"
						style={{ padding: "5px", fontSize: "8px", textAlign: "center" }}
					>
						A temporary hold of ${holdAmount} will be place on your card for incidentals
					</div>

					<div style={{ padding: "15px 15px 0px 15px" }}>
						<Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
							<CheckoutForm history={props.history} />
						</Elements>
					</div>
				</div>
			</div>
			<div style={{ paddingTop: "10px" }}>
				<MessageButton />
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	showPageLoader
};

HoldPayment.propTypes = {
	showPageLoader: PropTypes.func
};

export default connect(null, mapDispatchToProps)(HoldPayment);
