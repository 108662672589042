import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

export const AppButton = ({
	children,
	onClick,
	checkForDisable,
	gradientClassName,
}) => {
	return (
		<React.Fragment>
			<div className="next-btn-wrap">
				<div
					className={`gradient-revolve ${
						gradientClassName && gradientClassName
					} ${checkForDisable?.() ? "disable" : ""}`}
					onClick={onClick}
				>
					<div className="tap-here-wrap"></div>
					<div className="inner-circle">{children}</div>
				</div>
			</div>
		</React.Fragment>
	);
};

AppButton.propTypes = {
	onClick: PropTypes.func,
	children: PropTypes.node,
	checkForDisable: PropTypes.func,
	gradientClassName: PropTypes.string,
};
