import React, { useEffect, useState } from "react";
import "./styles.scss";
import { getReservationDetailsService } from "./service";
import { CHECK_IN_SERVICES } from "./constants";
import { parseReservationDetails } from "./helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showPageLoader } from "../Middleware/actionCreators";
import { createOrUpdateWebCheckInStatusService } from "../../common/services/AppServices";
import { WEB_CHECKIN_STATUS_TYPES } from "../../common/constants/AppConstants";
import { getReservationId } from "../../common/utils/localStorageUtils";
import { MessageButton } from "../../components/MessageButton";

const CheckIn = (props) => {
  const [reservationDetails, setReservationDetails] = useState(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });
    getReservationDetails();
  }, []);

  const getReservationDetails = async () => {
    props.showPageLoader(true);

    try {
      const reservationDetails = await getReservationDetailsService(
        CHECK_IN_SERVICES.getUserReservationDetails.apiEndpoint,
        {
          reservationId: await getReservationId(),
        }
      );

      setReservationDetails(parseReservationDetails(reservationDetails));
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const updateWebCheckInStatus = async () => {
    try {
      // set the web-checkin status to "DOC_NOT_UPLOADED"
      await createOrUpdateWebCheckInStatusService(
        WEB_CHECKIN_STATUS_TYPES.DOC_NOT_UPLOADED
      );
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const handleCheckInClick = async () => {
    props.showPageLoader(true);
    await updateWebCheckInStatus();
    props.history.push("/upload-document");
  };

  const redirectToErrorScreen = (error) => {
    props.history.push("/error");
  };

  return (
    <div className="checkin-screen aligning-middle">
      <div className="checking-details">
        <div className="arriving-details">
          <div className="check-titles">Arrival</div>
          <div className="check-date timing-details">
            {reservationDetails && reservationDetails.checkin_date}
          </div>
          <div className="check-time timing-details">
            {reservationDetails && reservationDetails.checkin_time}
          </div>
        </div>
        <div className="checkout-details">
          <div className="check-titles">Departure</div>
          <div className="check-date timing-details">
            {reservationDetails && reservationDetails.checkout_date}
          </div>
          <div className="check-time timing-details">
            {reservationDetails && reservationDetails.checkout_time}
          </div>
        </div>
      </div>
      <button className="gradient-revolve">
        <div className="tap-here-wrap"></div>
        <div className="inner-circle" onClick={handleCheckInClick}>
          <span className="tap-text">
            tap here to
            <strong>
              Pre Check-in <br />
              Now
            </strong>
          </span>
        </div>
      </button>
      <div style={{ paddingTop: "30px" }}>
        <MessageButton />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  showPageLoader,
};

CheckIn.propTypes = {
  showPageLoader: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(CheckIn);
