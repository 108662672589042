import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./styles.scss";
import PropTypes from "prop-types";

const AppPhoneInput = ({ phoneNumber, setPhoneNumber, disabled = true }) => {
  return (
    <div className="field-btn-wrap">
      <PhoneInput
        //country={"us"}
        enableLongNumbers={true}
        value={phoneNumber}
        onChange={(phone) => {
          setPhoneNumber?.(phone);
        }}
        autoFormat={true}
        disabled={disabled}
      />
    </div>
  );
};

AppPhoneInput.propTypes = {
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func.isRequired,
};

export default AppPhoneInput;
