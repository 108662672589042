import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showPageLoader } from "../../pages/Middleware/actionCreators";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  stripeHoldService,
  createOrUpdateWebCheckInStatusService,
  getPreCheckinMaxRetryService,
  updateSecurityBlockService,
} from "../../common/services/AppServices";
import { WEB_CHECKIN_STATUS_TYPES } from "../../common/constants/AppConstants";
import {
  getPhoneNumber,
  getPropertyId,
  getReservationId,
  getUserId,
} from "src/app/common/utils/localStorageUtils";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "rgb(240, 57, 122)",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "13px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#000",
      },
      "::placeholder": {
        color: "#a7a7a7",
      },
    },
    invalid: {
      iconColor: "#ff5656",
      color: "#ff5656",
    },
  },
};

const CardNumberField = ({ label, onChange }) => (
  <div className="FormRow">
    <label className="FormRowLabel">{label}</label>
    <CardNumberElement
      className="FormRowInput"
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  </div>
);

const CardExpiryField = ({ label, onChange }) => (
  <div className="FormRow">
    <label className="FormRowLabel">{label}</label>
    <CardExpiryElement
      className="FormRowInput"
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  </div>
);

const CardCvcField = ({ label, onChange }) => (
  <div className="FormRow">
    <label className="FormRowLabel">{label}</label>
    <CardCvcElement
      className="FormRowInput"
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    //className="retry-btn"
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#ff5656"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#fff"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const [billingDetails, setBillingDetails] = useState({
    name: "",
    address: {
      postal_code: "",
    },
  });

  const [preCheckinMaxRetry, setPreCheckinMaxRetry] = useState(null);
  const [retryQuantity, setRetryQuantity] = useState(0);

  useEffect(() => {
    //props.showPageLoader(true);
    getPreCheckinMaxRetry();
  }, []);

  const getPreCheckinMaxRetry = async () => {
    
    const propertyId = await getPropertyId();
    try {
      const response = await getPreCheckinMaxRetryService(
        "reservations/getPreCheckinMaxRetry",
        propertyId
      );

      setPreCheckinMaxRetry(response.precheckin_max_retry);
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const updateSecurityBlock = async () => {
  
    const propertyId = await getPropertyId();
    const reservationId = await getReservationId();

    try {
      const response = await updateSecurityBlockService(
        "reservations/updateSecurityBlock",
        propertyId,
        reservationId,
        true
      );

      return response;
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const updateWebCheckInStatus = async () => {
    try {
      // set the web-checkin status to "DOC_NOT_UPLOADED"
      const response = await createOrUpdateWebCheckInStatusService(
        WEB_CHECKIN_STATUS_TYPES.EXTRA_SERVICES
      );
      return response;
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const handleSubmit = async (event) => {
   
    try {
      event.preventDefault();
      props.showPageLoader(true);

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      if (error) {
        if (elements.getElement("card")) {
          elements.getElement("card").focus();
        }
        return;
      }

      if (cardComplete) {
        setProcessing(true);

        const payload = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: billingDetails,
        });

        if (payload.error) {
          setError(payload.error);
        } else {
          const myCard = elements.getElement(CardNumberElement);
          const token = await stripe.createToken(myCard);
          const { id } = payload.paymentMethod;

          const response = await stripeHoldService(
            token.token.id,
            payload.paymentMethod.card.last4,
            id
          );

          if (response && response.hasOwnProperty("id")) {
            for (let i = 1; i < 5; i++) {
              let response = await updateWebCheckInStatus();
              if (response === "Status updated successfully.") {
                setPaymentMethod(payload.paymentMethod);
                i = 5;
              }
            }
          } else {
            // increment retry quantity
            const retry = retryQuantity + 1;
            setRetryQuantity(retry);
            console.log(retry);

            if (retry >= preCheckinMaxRetry) {
              await updateSecurityBlock();
              redirectToSecurityBlockScreen();
            }

            if (response.hasOwnProperty("errorMessage")) {
              const obj = JSON.parse(response.errorMessage);

              if (obj.error === "Reservation has a payment hold already") {
                props.history.push("/extra-services");
              } else if (obj.error) {
                const responseError = new Error(obj.error);
                throw responseError;
              }
            } else {
              const responseError = new Error(
                "Payment declined, please try again."
              );
              throw responseError;
            }
          }
        }
      } else {
        const fieldsError = new Error("Please fill out all required fields");
        throw fieldsError;
      }
    } catch (error) {
      setError(error);
      setProcessing(false);
      props.showPageLoader(false);
    } finally {
      setProcessing(false);
      props.showPageLoader(false);
    }
  };

  const redirectToErrorScreen = (error) => {
    props.history.push("/error");
  };

  const redirectToSecurityBlockScreen = () => {
    props.history.push("/security-block");
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      name: "",
      address: {
        postal_code: "",
      },
    });
  };

  return paymentMethod ? (
    <Redirect
      to={{
        pathname: "/extra-services",
      }}
    />
  ) : (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup">
        <Field
          label="Name"
          id="name"
          type="text"
          placeholder="Name on the card"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
        />

        <CardNumberField
          label="Card #"
          name="number"
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        <CardExpiryField
          label="Due Date"
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />

        <CardCvcField
          label="CVC"
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        <Field
          label="ZIP Code"
          id="postal_code"
          type="text"
          placeholder="ZIP Code"
          required
          autoComplete="postal_code"
          value={billingDetails.address.postal_code}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              address: { postal_code: e.target.value },
            });
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        Confirm
      </SubmitButton>
    </form>
  );
};

const mapDispatchToProps = {
  showPageLoader,
};

CheckoutForm.propTypes = {
  showPageLoader: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(CheckoutForm);
