import { SHOW_PAGE_LOADER, SET_BLUR_BACKGROUND } from "./actionTypes";
import { MIDDLEWARE_INITIAL_STATE } from "./initialState";

export function MiddlewareReducer(state = MIDDLEWARE_INITIAL_STATE, action) {
	switch (action.type) {
		case SHOW_PAGE_LOADER:
			return {
				...state,
				toShowPageLoader: action.payload,
			};

		case SET_BLUR_BACKGROUND:
			return {
				...state,
				showBlurredBackground: action.payload,
			};

		default:
			return state;
	}
}
