import { SAVE_USER } from "./actionTypes";
import { LOGIN_INITIAL_STATE } from "./initialState";

export function LoginReducer(state = LOGIN_INITIAL_STATE, action) {
	switch (action.type) {
		case SAVE_USER:
			return {
				...state,
				cognitoUser: action.payload,
			};

		default:
			return state;
	}
}
