import React from "react";
import "./styles.scss";
import MessageIcon from "src/app/assets/images/message-icon.svg";

export const MessageButton = () => {
  return (
    <div className="message-button">
      <a href="sms:+18558218347" className="circle-message-wrap">
        <span className="phone-img">
          <img
            src={MessageIcon}
            alt=""
            style={{
              display: "flex",
              alignItems: "center",
              width: "30px",
              height: "30px",
            }}
          />
        </span>
      </a>
    </div>
  );
};
