import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";
import {
  getPhoneNumber,
  getPropertyId,
  getReservationId,
  getUserId,
} from "src/app/common/utils/localStorageUtils";


export const getPropertyDataService = async (apiEndpoint, params) => {
  const token = await getJwtToken();
  const propertyId = await getPropertyId();
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams({ propertyId })
      .GET()
      .then((response) => {
        resolve(JSON.parse(response.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
};

