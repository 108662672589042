export const USER_PASSWORD = "6784@!&A";

export const VERIFY_OTP_SERVICES = {};

//in seconds = 3mins
export const TIMER_DURATION = 180;

export const TIME_PERIOD_TO_ENABLE_RESEND = 130;

export const API_ERROR_TYPES = {
  CodeMismatchException: "CodeMismatchException",
  NOTAuthorizedException: "NotAuthorizedException",
};

export const ERROR_TYPES = {
  incorrectOTP: {
    title: "verification failed",
    subTitle: "Retry and enter correct OTP",
  },
  expiredOTP: {
    title: "Session is expired",
    subTitle: "Resend and enter new OTP",
  },
};
