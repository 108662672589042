import React, { useEffect, useState } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showPageLoader } from "../Middleware/actionCreators";
import { getAttachmentToDownloadService } from "./service";

const Attachments = (props) => {
  const [attachmentId, setAttachmentId] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });
    initialize();
  }, []);

  const initialize = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    setAttachmentId(queryParams.get("attachmentId"));
    await getAttachmentToDownload(queryParams.get("attachmentId"));
  };

  const getAttachmentToDownload = async (attachmentId) => {
    try {
      setLoading(true);
      const apiEndpoint = "reservations/getAttachmentToDownload";
      const response = await getAttachmentToDownloadService(
        apiEndpoint,
        attachmentId
      );
      console.log(response);
      setAttachment(response[0]);
      if (response) {
        window.location.href = response[0].attachment_url;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="attachment-container">
        {attachment && !loading ? (
          <>
            <div style={{ padding: "20px" }}>
              File: {attachment && attachment.original_name}
            </div>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ float: "right", marginRight: "10px" }}
                className="btn black-btn"
                href={attachment && attachment.attachment_url}
              >
                Download
              </a>
            </div>
          </>
        ) : null}

        {loading ? (
          <>
            <div>Loading...</div>
          </>
        ) : null}

        {!loading && !attachment ? (
          <>
            <div>Attachment not found</div>
          </>
        ) : null}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  showPageLoader,
};

Attachments.propTypes = {
  showPageLoader: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Attachments);
