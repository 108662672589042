import React from "react";
import { AppHeader } from "../../components/AppHeader";
import PropTypes from "prop-types";
import { Spinner } from "../../components/Spinner";
import "./styles.scss";

export const AppWrapper = ({ children, showLoader, showBlurredBackground }) => {
  return (
    // Show blurred background whenever there is any loader or any alert on the screen.
    <React.Fragment>
      <div
        id={"block-swipe-nav"}
        className={`page-wrapper mobile-screen ${
          showLoader || showBlurredBackground ? "loader-generated" : ""
        }`}
      >
        <AppHeader />
        {children}
      </div>
      {showLoader && <Spinner />}
    </React.Fragment>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.node,
};
