import React from "react";
import DocumentNotFoundIcon from "src/app/assets/images/document-notfound-icon.svg";
import Error from "src/app/assets/images/error.svg";

export const PreviewState = ({ url, portrait, oldImage }) => {
  return (
    <div className="uploaded-img">
      <img
        src={portrait || oldImage ? DocumentNotFoundIcon : url}
        alt=""
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = DocumentNotFoundIcon;
        }}
      />
      {(portrait || oldImage) && (
        <small>
          <img src={Error} alt="" style={{ width: "16px", height: "16px" }} />
          Please use a recent and landscape image
        </small>
      )}
    </div>
  );
};
