import React from "react";
import "react-phone-input-2/lib/style.css";
import "./styles.scss";
import PropTypes from "prop-types";

const AppEmailInput = ({ validateEmail, wrongEmail }) => {
  return (
    <>
      <div className="field-email-wrap">
        <input
          type="email"
          name="email"
          onBlur={(email) => validateEmail(email)}
          className="form-control email-control"
          placeholder=" Email address"
        />
      </div>
      {wrongEmail && (
        <span className="text-danger">Please add a correct email</span>
      )}
    </>
  );
};

AppEmailInput.propTypes = {
  // email: PropTypes.string.isRequired,
  setEmail: PropTypes.func,
};

export default AppEmailInput;
