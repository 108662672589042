import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export const getFormattedTime = (seconds) => {
	const mins = Math.floor(seconds / 60);
	const secs = Math.floor(seconds % 60);

	const time =
		mins.toString().padStart(2, "0") + ":" + secs.toString().padStart(2, "0");
	return time;
};

export const showConfirmationAlert = (onOkClick) => {
	confirmAlert({
		title: "Error",
		message:
			"Oops! It seems like you have been logged out. Please login again..",
		buttons: [
			{
				label: "Ok",
				onClick: onOkClick,
			},
		],
		closeOnClickOutside: false,
	});
};
