import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

export const ErrorIncorrectInfo = ({ errorMessage }) => {
	return (
		<div className="error-details">
			<span className="incorrect">{errorMessage?.title}</span>
			<span className="enter-number">{errorMessage?.subTitle}</span>
		</div>
	);
};

ErrorIncorrectInfo.propTypes = {
	errorMessage: PropTypes.object.isRequired,
};
