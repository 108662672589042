import React, { useState, useEffect } from "react";
import AppPhoneInput from "../../components/AppPhoneInput";
import AppEmailInput from "../../components/AppEmailInput";
import { getFormattedDate } from "../../common/utils/dateUtils";
import { DATE_FORMATS } from "../../common/constants/AppConstants";
import { Auth } from "aws-amplify";
import { USER_PASSWORD } from "../../common/constants/AppConstants";
import { Redirect } from "react-router";
import { LOGIN_SERVICES } from "./constants";
import {
  checkIfUserExistsService,
  saveUserService,
  getReservationsByPhoneNumberService,
  shareReservationService,
  getReservationByReservationIdService,
} from "./service";
import { connect } from "react-redux";
import { saveUser } from "./actionCreators";
import "./styles.scss";
import { MessageButton } from "../../components/MessageButton";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import CloseIcon from "src/app/assets/images/close-icon.svg";

import PropTypes from "prop-types";
import { showPageLoader } from "../Middleware/actionCreators";
import {
  setItemIntoLocalStoarge,
  getPhoneNumber,
  getShare,
  getReservationId,
  getPropertyId,
} from "../../common/utils/localStorageUtils";
import { LOCAL_STORAGE_KEYS } from "../../common/constants/AppConstants";
import { getCognitoIdentityId } from "../../common/utils/sessionUtils";
import isEmail from "validator/lib/isEmail";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Login = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [multipleReservations, setMultipleReservations] = useState(false);
  const [listReservations, setListReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState("");
  const [redirectToNextScreen, setRedirectToNextScreen] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const [close, setClose] = useState(false);

  useEffect(() => {
    setPhoneNumberOnMount();
  }, []);

  const phoneIsApproved = () => {
    const list = JSON.parse(localStorage.getItem("approvedPhones") || "[]");

    if (
      list.length &&
      list.find((item) => item === btoa("+" + phoneNumber.replace("+", "")))
    ) {
      return true;
    }

    return false;
  };

  const emailIsApproved = () => {
    const list = JSON.parse(localStorage.getItem("approvedEmails") || "[]");

    if (list.length && list.find((item) => item === email)) {
      return true;
    }

    return false;
  };
  const validateEmail = (e) => {
    e.preventDefault();
    const email = e.target.value;
    if (isEmail(email)) {
      setEmail(email);
      setWrongEmail(false);
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
      setEmail("");
      setWrongEmail(true);
    }
  };

  const setPhoneNumberOnMount = async () => {
    try {
      props.showPageLoader(true);
      const phone = await getPhoneNumber();
      const share = await getShare();
      const reservationId = await getReservationId();
      const propertyId = await getPropertyId();

      if (phone) {
        setPhoneNumber(atob(phone));
        if (share && reservationId) {
          const sharedReservation = await getReservationByReservationId(
            atob(reservationId)
          );

          if (sharedReservation && sharedReservation.id) {
            setSelectedReservation(sharedReservation.id);
          }
        } else {
          let response = [];
          response = await getReservationsByPhoneNumber(
            atob(phone),
            propertyId
          );

          if (response && response.length) {
            if (response.length > 1) {
              setMultipleReservations(true);
              const temp = response.map((item) => {
                return {
                  label: `Arrival: ${getFormattedDate(
                    item.checkin_date,
                    DATE_FORMATS.dateFormat
                  )}, Departure: ${getFormattedDate(
                    item.checkout_date,
                    DATE_FORMATS.dateFormat
                  )} | ${item.room_type_name}`,
                  value: item.id,
                };
              });
              setListReservations(temp);
            } else {
              setSelectedReservation(response[0].id);
              setMultipleReservations(false);
            }
          } else {
            redirectToReservationNotFoundScreen();
          }
        }
      }
    } catch (error) {
    } finally {
      props.showPageLoader(false);
    }
  };

  const setDefaultValues = async (queryParams) => {
    if (queryParams) {
      await setItemIntoLocalStoarge(
        LOCAL_STORAGE_KEYS.queryParams,
        JSON.stringify(queryParams)
      );
    }
  };

  const onNextClick = async () => {
    if (email && isEmail(email)) {
      props.showPageLoader(true);

      const propertyId = await getPropertyId();

      setDefaultValues({
        email: email,
        phone: btoa(phoneNumber),
        propertyId: propertyId,
        reservationId: selectedReservation,
      });

      const userExists = await checkIfUserAlreadyExists(
        "+" + phoneNumber.replace("+", "")
      );
      if (userExists) {
        return signInUser("+" + phoneNumber.replace("+", ""), USER_PASSWORD);
      }
      signUpUser("+" + phoneNumber.replace("+", ""), USER_PASSWORD);
    } else {
      setWrongEmail(true);
    }
  };

  const checkIfUserAlreadyExists = async (phoneNumber) => {
    try {
      const response = await checkIfUserExistsService(
        LOGIN_SERVICES.checkIfUserAlreadyExists.apiEndpoint,
        {
          phoneNumber,
          email,
        }
      );
      if (response.statusCode === 200) {
        return response.userExists;
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const signUpUser = async (phoneNumber, password) => {
    try {
      const data = await Auth.signUp({
        username: phoneNumber,
        password,
        attributes: {
          email,
        },
      });
      const cognitoIdentityId = await getCognitoIdentityId();
      await saveUserIntoDB(phoneNumber, data.userSub, cognitoIdentityId);
      await signInUser(phoneNumber, USER_PASSWORD, true);
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const signInUser = async (phoneNumber, password, isInitialSignIn) => {
    try {
      if (!phoneIsApproved()) {
        var data = await Auth.signIn({
          username: phoneNumber,
          password,
        });
      }

      props.saveUser(data);
      setRedirectToNextScreen(true);
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const getReservationsByPhoneNumber = async (phoneNumber, propertyId) => {
    try {
      const response = await getReservationsByPhoneNumberService(
        LOGIN_SERVICES.getReservationsByPhoneNumber.apiEndpoint,
        {
          phoneNumber,
          propertyId,
        }
      );
      if (response) {
        return response;
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const getReservationByReservationId = async (reservationId) => {
    try {
      const response = await getReservationByReservationIdService(
        LOGIN_SERVICES.getReservationByReservationId.apiEndpoint,
        {
          reservationId,
        }
      );
      if (response) {
        return response;
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const shareReservation = async (
    email,
    phoneNumber,
    reservationId,
    propertyId
  ) => {
    try {
      const response = await shareReservationService(
        LOGIN_SERVICES.shareReservation.apiEndpoint,
        {
          email,
          phoneNumber,
          reservation_id: reservationId,
          propertyId,
        }
      );
      if (response) {
        return response;
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const saveUserIntoDB = async (phoneNumber, cognitoId, cognitoIdentityId) => {
    try {
      const response = await saveUserService(
        LOGIN_SERVICES.saveUser.apiEndpoint,
        {
          phoneNumber,
          cognitoId,
          cognitoIdentityId,
        }
      );

      setItemIntoLocalStoarge(
        LOCAL_STORAGE_KEYS.userId,
        response.user && response.user.id
      );
    } catch (error) {
      props.showPageLoader(false);
      redirectToErrorScreen(error);
    }
  };

  const redirectToErrorScreen = (error) => {
    props.history.push("/error");
  };

  const redirectToReservationNotFoundScreen = () => {
    props.history.push("/reservation-not-found");
  };

  const handleShareClose = async (e) => {
    setClose(true);
    try {
      const propertyId = await getPropertyId();
    } catch (error) {}
    setClose(false);
  };

  const handleShare = async () => {
    setClose(true);
    try {
      props.showPageLoader(true);
      const propertyId = await getPropertyId();
      await shareReservation(
        email,
        "+" + phoneNumber.replace("+", ""),
        selectedReservation,
        propertyId
      );
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
    setClose(false);
  };

  return (
    <React.Fragment>
      {redirectToNextScreen && (!phoneIsApproved() || !emailIsApproved()) && (
        <Redirect
          to={{
            pathname: "/verify-otp",
            state: {
              phoneNumber,
            },
          }}
        />
      )}
      {redirectToNextScreen && phoneIsApproved() && emailIsApproved() && (
        <Redirect
          to={{
            pathname: "/check-in",
          }}
        />
      )}

      <div className="phone-field">
        <label className="phone-title">enter mobile number</label>
        <AppPhoneInput
          phoneNumber={phoneNumber || null}
          setPhoneNumber={setPhoneNumber}
          disabled={false}
        />
        <br />
        <label className="phone-title">enter email address</label>
        <AppEmailInput validateEmail={validateEmail} wrongEmail={wrongEmail} />
        <br />{" "}
        {listReservations.length ? (
          <>
            <Dropdown
              options={listReservations && listReservations}
              onChange={(event) => {
                setSelectedReservation(event.value);
              }}
              placeholder="Select a reservation"
            />
          </>
        ) : null}
        <br /> <br />
        <div
          style={{
            width: "100%",
            float: "right",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <>
            {!close && (
              <Popup
                trigger={
                  <button
                    style={{ float: "right", marginRight: "10px" }}
                    className={
                      selectedReservation
                        ? "btn black-btn"
                        : "black-btn-disabled"
                    }
                    disabled={selectedReservation ? false : true}
                  >
                    Share
                  </button>
                }
                modal
              >
                <div
                  style={{
                    float: "right",
                    marginRight: "10px",
                    paddingBottom: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    handleShareClose(e);
                  }}
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    style={{
                      width: "14px",
                      height: "14px",
                    }}
                  />
                </div>
                <div className="content">
                  <h2>Share Your Booking</h2>
                  <h4>
                    The Share option allows you to share this reservation with
                    another person that needs to process the Pre Check-in.
                  </h4>
                  <button
                    className="btn black-btn"
                    onClick={() => {
                      handleShare();
                    }}
                  >
                    Continue
                  </button>{" "}
                </div>
              </Popup>
            )}
            <button
              onClick={onNextClick}
              className={
                selectedReservation ? "btn black-btn" : "black-btn-disabled"
              }
              disabled={selectedReservation ? false : true}
            >
              Next
            </button>
          </>
        </div>
        <div style={{ paddingTop: "120px" }}>
          <MessageButton />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { cognitoUser } = state.login;

  return {
    cognitoUser,
  };
};

const mapDispatchToProps = {
  saveUser,
  showPageLoader,
};

Login.propTypes = {
  cognitoUser: PropTypes.object,
  saveUser: PropTypes.func,
  showPageLoader: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
