const AWS_CONFIG = {
  AWS: {
    Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_S3_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
      authenticationFlowType: "CUSTOM_AUTH",
      scope: ["openid", "email", "phone", "profile", "aws.cognito.signin.user.admin"],
      oauth: {
        domain: "tuck-infra-dev-user.auth.us-east-1.amazoncognito.com",
        scope: ["openid", "email", "phone", "profile", "aws.cognito.signin.user.admin"],

        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
        responseType: "code",
      },
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_BUCKET_NAME, // REQUIRED -  Amazon S3 bucket
        region: process.env.REACT_APP_S3_REGION, //OPTIONAL - S3 Buckets doesn't require any region selection
      },
    },
  },
};

export default AWS_CONFIG;
