import React from "react";
import { ErrorIcon } from "../../ErrorIcon";

export const ErrorState = ({ message }) => {
	return (
		<div className="uploadError-state-wrap">
			<div className="error-text">
				<ErrorIcon/>
				{message}
			</div>
			<div className="retry-upload">TAP HERE TO RETRY</div>
		</div>
	);
};
