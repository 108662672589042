import React from "react";
import NotFoundIcon from "../../assets/images/not-found.png";
import "./styles.scss";
import { MessageButton } from "../../components/MessageButton";

export const NotFound = (props) => {
  const handleBackClick = () => {
    props.history.push("/login");
  };
  return (
    <div className="not-found-wrap">
      <div className="not-found-img">
        <img src={NotFoundIcon} alt="" />
      </div>
      <div class="not-found-desc">
        <h1>404</h1>
        <span>The page you are looking for could not be found.</span>
        <button className="retry-btn" onClick={handleBackClick}>
          Return To Home
        </button>
      </div>
      <div style={{ paddingTop: "60px" }}>
        <MessageButton />
      </div>
    </div>
  );
};
