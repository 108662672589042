import { LOCAL_STORAGE_KEYS } from "../constants/AppConstants";

export const setItemIntoLocalStoarge = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (err) {}
};

export const getItemFromLocalStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (err) {}
};

export const removeItemFromLocalStorage = (key) => {
  try {
    return localStorage.removeItem(key);
  } catch (err) {}
};

// USER ID
export const getUserId = async () => {
  return getItemFromLocalStorage(LOCAL_STORAGE_KEYS.userId);
};

//RESERVATION ID
export const getReservationId = async () => {
  return JSON.parse(getItemFromLocalStorage(LOCAL_STORAGE_KEYS.queryParams))
    .reservationId;
};

//Phone no.
export const getPhoneNumber = async () => {
  return JSON.parse(getItemFromLocalStorage(LOCAL_STORAGE_KEYS.queryParams))
    .phone;
};

//Share Option.
export const getShare = async () => {
  return JSON.parse(getItemFromLocalStorage(LOCAL_STORAGE_KEYS.queryParams))
    .shared;
};

//Property Id
export const getPropertyId = async () => {
  return JSON.parse(getItemFromLocalStorage(LOCAL_STORAGE_KEYS.queryParams))
    .propertyId;
};

export const getEmail = async () => {
  return JSON.parse(getItemFromLocalStorage(LOCAL_STORAGE_KEYS.queryParams))
    .email;
};
