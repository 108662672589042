import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

export const AppCheckBox = ({ isChecked, toggleChecked }) => {
  return (
    <label className="checkbox-container">
      <input
        type="checkbox"
        onChange={(event) => {
          toggleChecked(event.target.checked);
        }}
        checked={isChecked}
      />
      <span className="checkmark"></span>
    </label>
  );
};

AppCheckBox.propTypes = {
  isChecked: PropTypes.bool,
  toggleChecked: PropTypes.func,
};
