import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import "./styles.scss";
import GenericErrorImg from "src/app/assets/images/error-image.png";
import { ERROR_INFO } from "./constants";
import { MessageButton } from "../../components/MessageButton";
import { removeItemFromLocalStorage } from "../../common/utils/localStorageUtils";

export const GenericError = () => {
  const history = useHistory();

  useEffect(() => {
    removeLocalStorage();
  }, []);

  const removeLocalStorage = async () => {
    Auth.signOut();
    await removeItemFromLocalStorage("email");
    await removeItemFromLocalStorage("approvedEmails");
    await removeItemFromLocalStorage("accessToken");
    await removeItemFromLocalStorage("approvedPhones");
    await removeItemFromLocalStorage("timerStartsTime");
  };

  const goBackToPreviousScreen = () => {
    history.push("/login");
  };

  return (
    <div className="errorState-wrapper">
      <div className="error-state">
        <div className="error-img">
          <img src={GenericErrorImg} alt="error" />
        </div>
        <div className="errorState-text">
          {ERROR_INFO.title}
          <span>{ERROR_INFO.subTitle}</span>
        </div>
        <button className="retry-btn" onClick={goBackToPreviousScreen}>
          Retry
        </button>
        <div style={{ paddingTop: "100px" }}>
          <MessageButton />
        </div>
      </div>
    </div>
  );
};
