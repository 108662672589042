export const SIGN_UP_ERROR_TYPES = {
  UsernameExistsException: "UsernameExistsException",
};

export const LOGIN_SERVICES = {
  checkIfUserAlreadyExists: {
    apiEndpoint: "app/auth/checkIfGuestUserExists",
  },
  getReservationsByPhoneNumber: {
    apiEndpoint: "getReservationsByPhoneNumber",
  },
  shareReservation: {
    apiEndpoint: "/reservations/shareUrl",
  },
  getReservationByReservationId: {
    apiEndpoint: "/getReservationByReservationId",
  },
  saveUser: {
    apiEndpoint: "app/auth/saveGuestUser",
  },
  postUpdateReservation: {
    apiEndpoint: "/reservations/postUpdateReservation",
  },
};

export const INCORRECT_NUMBER = {
  title: "incorrect number",
  subTitle: "Enter Mobile number where reservation was made",
};
