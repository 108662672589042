import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Storage } from "aws-amplify";
import { UploadState } from "./components/NormalState";
import { ErrorState } from "./components/ErrorState";
import { UploadingState } from "./components/UploadingState";
import { PreviewState } from "./components/PreviewState";
import { MAX_SIZE, ERROR_MESSAGES } from "./constants";
import CloseIcon from "../../assets/images/close-icon.svg";
import Landscape from "../../assets/images/landscape.png";
import {
  resizeFile,
  dataURIToBlob,
  checkIfSizeExceeds,
  checkImageDimensions,
  checkAspectRatio,
  checkImageDate,
} from "./helper";
import {
  getUserId,
  getReservationId,
} from "../../common/utils/localStorageUtils";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";

export const UploadImage = ({
  disabled,
  onImageUploadToS3,
  s3Key,
  level,
  setS3Key,
  alreadyUploadedImage,
  props,
  validationCounter,
  setValidationCounter,
  // getRef
}) => {
  const [isUploading, setUploading] = useState(false);
  const [uploadedPerc, setUploadedPerc] = useState(0);
  const [isError, setError] = useState(false);
  const [url, setUrl] = useState(alreadyUploadedImage);
  const [isSizeExceeds, setSizeExceeds] = useState();
  const [portrait, setPortrait] = useState(false);
  const [oldImage, setOldImage] = useState(false);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  /** alreadyUploadedImage is a prop
   * that comes from its parent.
   * As soon as we get this value, we set it
   * to our url state variable to show preview.
   */
  useEffect(() => {
    alreadyUploadedImage && setUrl(alreadyUploadedImage);
  }, [alreadyUploadedImage]);

  const onChange = async (event) => {
    //  Resetting if last state was error state
    if (isError) {
      setError(false);
    }
    if (isSizeExceeds) {
      setSizeExceeds(false);
    }

    const file = event.target.files && event.target.files[0];
    // android chrome gives error when picker is opened and image is not selected.
    if (!file) return;
    // Checking if file size limit exceeds the threshold
    if (file && checkIfSizeExceeds(file, MAX_SIZE)) {
      setSizeExceeds(true);
      resetState();
      return;
    }
    //The URL.createObjectURL() method takes an object (like our file) and creates a temporary local URL that is tied to the document in which it is created
    const tempUrl = URL.createObjectURL(file);
    const dimensions = await checkImageDimensions(tempUrl);
    const requiredAspectRatio = checkAspectRatio(dimensions);
    const requiredImageDate = checkImageDate(file);

    // increase the validation counter if the image is not landscape
    if (!requiredAspectRatio) {
      setValidationCounter(validationCounter + 1);
    }

    setOldImage(!requiredImageDate);
    setPortrait(!requiredAspectRatio);

    setUrl(tempUrl);

    //uploading to s3
    if (requiredAspectRatio && requiredImageDate) {
      setUploading(true);
      const blobFile = await getImageForS3Upload(file);
      await uploadOnS3(blobFile);
    } else if (validationCounter >= 3) {
      //accept without validation on the third try
      setPortrait(false);
      setUploading(true);
      const blobFile = await getImageForS3Upload(file);
      await uploadOnS3(blobFile);
    }
  };

  const getImageForS3Upload = async (file) => {
    try {
      // Resizing the image to decrease it's size so that upload can be faster and also it would result to cheap cost at s3
      var resizedFile = await resizeFile(file);
      var blobFile = dataURIToBlob(resizedFile);
    } catch (err) {
      resetState();
    } finally {
      return blobFile;
    }
  };

  const uploadOnS3 = async (blobFile) => {
    try {
      const userId = await getUserId();
      const resId = await getReservationId();

      //pre-pending userId to the s3 key name just to uniquely identify the documents
      const result = await Storage.put(
        `user${userId}_res${resId}_${s3Key}`,
        blobFile,
        {
          level, //as these are private docs of a user, so we are using 'private' level here.
          progressCallback(progress) {
            const remainingPart = (progress.loaded / progress.total) * 100;
            setUploadedPerc(remainingPart);
          },
        }
      );

      //invoking the callback on successful upload to S3
      onImageUploadToS3(result.key);
    } catch (error) {
      resetState();
    } finally {
      setUploading(false);
    }
  };

  // Reset everything
  const resetState = () => {
    setUploading(false);
    setError(true);
    setS3Key(null);
  };

  return (
    <div>
      <div className="upload-cancel-wrap">
        <span className="upload-title">UPLOAD ID</span>
        {url && (
          <button
            onClick={() => document.getElementById("file").click()}
            className="edit-btn"
          >
            edit
          </button>
        )}
      </div>
      <div
        className={`upload-documents-wrapper  ${
          isUploading && "disabled-tap"
        } ${disabled && "disabled"}`}
        onClick={() => setOpen((o) => !o)}
      >
        {/* Input type to browse an image */}
        <input
          className="input-upload"
          id={"file"}
          type="file"
          capture="environment"
          onChange={(event) => onChange(event)}
          accept="image/png,image/jpeg"
        />
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
          <div className="modal">
            <div
              style={{
                float: "right",
                marginRight: "10px",
                paddingBottom: "5px",
                cursor: "pointer",
              }}
            >
              <img
                src={CloseIcon}
                alt=""
                style={{
                  width: "14px",
                  height: "14px",
                }}
                onClick={closeModal}
              />
            </div>
            <div className="content">
              <h2>Upload ID</h2>
              <h4>
                Please place your phone in Landscape/Horizontal mode while
                taking the picture.
              </h4>
              <br />
              <img src={Landscape} alt="" className="landscapeImage" />
              <label
                htmlFor="file"
                className="btn black-btn button-upload-pre"
                onClick={closeModal}
              >
                Continue
              </label>{" "}
            </div>
          </div>
        </Popup>

        {/* Different states of picture-box */}
        {isError ? (
          <ErrorState
            message={
              isSizeExceeds
                ? ERROR_MESSAGES.sizeExceeds
                : ERROR_MESSAGES.generic
            }
          />
        ) : isUploading ? (
          <UploadingState uploadedPerc={uploadedPerc} />
        ) : uploadedPerc === 100 || url ? (
          <PreviewState url={url} portrait={portrait} oldImage={oldImage} />
        ) : (
          <UploadState />
        )}
      </div>
    </div>
  );
};

UploadImage.propTypes = {
  disabled: PropTypes.bool,
  onImageUploadToS3: PropTypes.func,
  s3Key: PropTypes.string.isRequired,
  level: PropTypes.string,
  setS3Key: PropTypes.func,
  alreadyUploadedImage: PropTypes.string,
};
