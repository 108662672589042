export const APP_ENTRY_TYPES = {
  SignIn: "SignIn",
  SignUp: "SignUp",
};

// Creating randomly because we are not using password upfront for authentication purpose instead we are using OTP which will be sent to the user on successful sign-up or sign-in
export const USER_PASSWORD = "Tuck1$ventures";

export const LOCAL_STORAGE_KEYS = {
  accessToken: "accessToken",
  timerStartsTime: "timerStartsTime",
  reservationId: "reservationId",
  userId: "userId",
  phoneNumber: "phoneNumber",
  queryParams: "queryParams",
  share: "share",
  email: "email",
};

export const WEB_CHECKIN_STATUS_TYPES = {
  LOGGED_IN: "LOGGED_IN",
  DOC_NOT_UPLOADED: "DOC_NOT_UPLOADED",
  DOC_UPLOADED: "DOC_UPLOADED",
  PHOTO_UPLOADED: "PHOTO_UPLOADED",
  HOLD_PAYMENT: "HOLD_PAYMENT",
  EXTRA_SERVICES: "EXTRA_SERVICES",
  WEB_CHECKIN: "WEB_CHECKIN",
};

/** to manage navigation according to
 * the user status present in db
 */
export const Status_And_Route_Mapping = {
  "Logged-in": "/check-in",
  "Doc-not-uploaded": "/upload-document",
  "Doc-uploaded": "/upload-photo",
  "Photo-uploaded": "/hold-payment",
  "Hold-payment": "/hold-payment",
  "Extra-services": "/extra-services",
  "Web-check-in": "/check-in-success",
  Login: "/login",
};

export const DATE_FORMATS = {
  dayTimeFormat: "ddd, MMM DD, YYYY",
  dateFormat: "MMM DD, YYYY",
  standardDateFormat: "MM/DD/yyyy",
};
