import React from "react";
import SelfieIcon from "src/app/assets/images/selfie-icon.png";
import Error from "src/app/assets/images/error.svg";

export const PreviewState = ({ url, portrait, oldImage }) => {
  return (
    <div className="uploaded-img">
      <img
        src={portrait || oldImage ? SelfieIcon : url}
        alt=""
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = SelfieIcon;
        }}
      />
      {(portrait || oldImage) && (
        <small>
          <img src={Error} alt="" style={{ width: "16px", height: "16px" }} />
          Please use a recent and landscape image
        </small>
      )}
    </div>
  );
};
