import OtpInput from "react-otp-input";
import React, { useEffect } from "react";
import "./styles.scss";

export const AppOtpInput = ({ otp, setOtp }) => {
  useEffect(() => {
    var ins = document.querySelectorAll('input[type="number"]');

    if (ins.length) {
      var in1 = ins[0];
      in1.addEventListener("input", splitNumber);
      /*this is done to hide the cursor from the second field on iOS Chrome
            as I was adding the event on first input and as the first input gets filled
             the cursor automatically moves to second input.*/
      ins[1].style.caretColor = "transparent";
    }
  }, []);

  const splitNumber = function (e) {
    let data = e.data || e.target.value; // Chrome doesn't get the e.data, it's always empty, fallback to value then.
    if (!data) return; // Shouldn't happen, just in case.
    if (data.length === 1) return; // Here is a normal behavior, not a paste action.
    setOtp(data);
  };

  return (
    <OtpInput
      onChange={(otp) => {
        setOtp(otp);
      }}
      numInputs={6}
      value={otp}
      containerStyle="otp-field"
      inputStyle="input-style"
      shouldAutoFocus={true}
      isInputNum={true}
    />
    //<div></div>
  );
};
