import AxiosBuilder from "./AxiosBuilder";
import { Storage } from "aws-amplify";
import { getJwtToken } from "../utils/sessionUtils";
import {
  getUserId,
  getReservationId,
  getPropertyId,
  getPhoneNumber,
} from "../utils/localStorageUtils";

export const createOrUpdateWebCheckInStatusService = async (status) => {
  const token = await getJwtToken();
  const apiEndpoint = "reservation/upsertGuestUserReservationCheckInStatus";
  const reservationId = await getReservationId();
  const userId = await getUserId();
  const propertyId = await getPropertyId();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId,
        userId,
        checkInStatus: status,
        propertyId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** fetch s3 url by passing s3 key and congnito id */
export const fetchS3ImageUrlByS3Key = async (s3Key, cognitoId) => {
  const url = await Storage.get(s3Key, {
    level: "protected",
    identityId: cognitoId,
  });
  return url;
};

export const stripeHoldService = async (
  stripeToken,
  fourLastNumber,
  paymentMethodId
) => {
  const token = await getJwtToken();
  const apiEndpoint = "stripe/hold";
  const reservationId = await getReservationId();
  const propertyId = await getPropertyId();
  const phone = await getPhoneNumber();
  const phoneNumber = "+" + atob(phone).replace("+", "");
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        stripeToken,
        reservationId,
        propertyId,
        fourLastNumber,
        phoneNumber,
        paymentMethodId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPreCheckinMaxRetryService = async (apiEndpoint, propertyId) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateSecurityBlockService = async (apiEndpoint, propertyId, reservationId, securityBlock) => {
  const token = await getJwtToken();
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId,
        propertyId,
        securityBlock
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        resolve(JSON.parse(response.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
};
