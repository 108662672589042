import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "../../common/utils/sessionUtils";
import { getPropertyId } from "../../common/utils/localStorageUtils";

export const checkIfUserExistsService = async (apiEndpoint, params) => {
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withParams({
        phone_number: btoa(params.phoneNumber),
        email: params.email,
      })
      .GET()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getReservationByReservationIdService = async (
  apiEndpoint,
  params
) => {
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withParams({ reservationId: params.reservationId })
      .GET()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getReservationsByPhoneNumberService = async (
  apiEndpoint,
  params
) => {
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        phoneNumber: params.phoneNumber,
        propertyId: params.propertyId,
      }),
    })
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const shareReservationService = async (apiEndpoint, params) => {
  const propertyId = await getPropertyId();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        email: params.email,
        phone_number: params.phoneNumber,
        propertyId: parseInt(propertyId),
        reservation_id: params.reservation_id,
      }),
    })
      .POST()
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveUserService = async (apiEndpoint, params) => {
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        phone_number: params.phoneNumber,
        cognito_id: params.cognitoId,
        cognito_identity_id: params.cognitoIdentityId,
      }),
    })
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postUpdateReservation = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        email: params.email,
        reservationId: params.reservationId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
