import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import {
  getUserId,
  getReservationId,
} from "../../common/utils/localStorageUtils";
import { getJwtToken } from "../../common/utils/sessionUtils";

export const getUserDetailsService = async (apiEndpoint, params) => {
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withParams({ phone_number: btoa(params.phoneNumber) })
      .GET()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getWebCheckInStatusService = async (apiEndpoint) => {
  const userId = await getUserId();
  const reservationId = await getReservationId();
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams({ userId, reservationId })
      .GET()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
