import React from "react";

export const UploadingState = ({ uploadedPerc }) => {
	return (
		<React.Fragment>
			<div className="progress-bar-wrap">
				<div className="progress-bar-space">
					<span
						className="progressBar-width"
						style={{ width: `${uploadedPerc}%` }}
					></span>
				</div>
				<div className="remaining-data">{`${Math.floor(
					uploadedPerc,
				)}% of 100%`}</div>
			</div>
		</React.Fragment>
	);
};
