import React from "react";
import { AppButton } from "../AppButton";
import { CrossIcon } from "../CrossIcon";
import { CheckIcon } from "../CheckIcon";
import "./styles.scss";
import PropTypes from "prop-types";

export const Eula = ({ text, onAccept, onDismiss, activeClass }) => {
  return (
    <div className={`terms-wrapper ${activeClass} `}>
      <h3>Terms of Use and Booking Agreement</h3>
      {/* Here we are rendering the html response from the server */}
      <div
        className="eula-terms"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>

      <div className="btn-wrapper">
        {/* Dismiss button */}
        <AppButton onClick={onDismiss} gradientClassName="dismiss-btn">
          <CrossIcon />
        </AppButton>
        {/* Accept button */}
        <AppButton onClick={onAccept} className="tick-class">
          <CheckIcon />
        </AppButton>
      </div>
    </div>
  );
};

Eula.propTypes = {
  text: PropTypes.string,
  onAccept: PropTypes.func,
  onDismiss: PropTypes.func,
  activeClass: PropTypes.any,
};
