import React, { useEffect, useState } from "react";
import "./styles.scss";
import { UPLOAD_DOCUMENT_SERVICES } from "./constants";
import {
  getTermsAndConditionsService,
  getUserUploadedDocumentService,
  updateGuestUserReservationPhotoService,
} from "./service";
import { UploadGuestPhoto } from "../../components/UploadGuestPhoto";
import { AppCheckBox } from "../../components/AppCheckBox";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showPageLoader } from "../Middleware/actionCreators";
import { getCognitoIdentityId } from "../../common/utils/sessionUtils";
import { Eula } from "../../components/Eula";
import {
  createOrUpdateWebCheckInStatusService,
  fetchS3ImageUrlByS3Key,
} from "../../common/services/AppServices";
import { WEB_CHECKIN_STATUS_TYPES } from "../../common/constants/AppConstants";
import { Redirect } from "react-router";
import {
  getPropertyId,
  getReservationId,
  getUserId,
} from "../../common/utils/localStorageUtils";
import { MessageButton } from "../../components/MessageButton";

const UploadPhoto = (props) => {
  const [isAgreementAccepted, setAgreementAccepted] = useState(false);
  const [docS3Key, setDocS3key] = useState(false);
  const [showEula, setShowEula] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [redirectToHoldPayment, setRedirectToHoldPayment] = useState(false);
  const [alreadyUploadedImageUrl, setAlreadyUploadedImageUrl] = useState(null);
  const [validationCounter, setValidationCounter] = useState(1);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });
    props.showPageLoader(true);
    Promise.all([getUserUploadedDocument(), getTermsAndConditions()]).then(
      () => {
        props.showPageLoader(false);
      }
    );
  }, []);

  const getTermsAndConditions = async () => {
    const { apiEndpoint } = UPLOAD_DOCUMENT_SERVICES.getTermsAndConditions;
    try {
      const propertyId = await getPropertyId();
      const response = await getTermsAndConditionsService(apiEndpoint, {
        propertyId,
      });
      response && setTermsAndConditions(response);
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const getUserUploadedDocument = async () => {
    const { apiEndpoint } = UPLOAD_DOCUMENT_SERVICES.getUserUploadedDocuments;
    try {
      const reservationId = await getReservationId();
      const userId = await getUserId();
      const response = await getUserUploadedDocumentService(apiEndpoint, {
        reservationId,
        userId,
      });

      if (response && response.length > 0) {
        const [{ guest_photo_s3_key, cognito_identity_id }] = response;

        setDocS3key(guest_photo_s3_key);
        const imageUrl = await fetchS3ImageUrlByS3Key(
          guest_photo_s3_key,
          cognito_identity_id
        );
        setAlreadyUploadedImageUrl(imageUrl);
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const onImageUploadToS3 = async (s3Key) => {
    setDocS3key(s3Key);

    for (let i = 1; i < 10; i++) {
      let response = await updateGuestUserReservationPhoto(s3Key);
      if (response === "Guest photo updated successfully.") {
        i = 10;
      }
    }
  };

  const updateGuestUserReservationPhoto = async (s3Key) => {
    const { apiEndpoint } =
      UPLOAD_DOCUMENT_SERVICES.updateGuestUserReservationPhoto;

    const cognitoIdentityId = await getCognitoIdentityId();

    try {
      const response = await updateGuestUserReservationPhotoService(
        apiEndpoint,
        {
          s3Key,
          cognitoIdentityId,
        }
      );
      return response;
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(true);
    props.showPageLoader(true);

    try {
      const isValidated = checkIfAllDataFilled();

      if (isValidated && alreadyUploadedImageUrl && docS3Key) {
        await updateGuestUserReservationPhoto(docS3Key);

        const response = await updatePhotoUploadedInStatus();
        if (response && response === "Status updated successfully.") {
          setRedirectToHoldPayment(true);
        }
      }
    } catch (error) {
      props.showPageLoader(false);
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const updatePhotoUploadedInStatus = async () => {
    props.showPageLoader(true);

    try {
      const response = await createOrUpdateWebCheckInStatusService(
        WEB_CHECKIN_STATUS_TYPES.PHOTO_UPLOADED
      );
      return response;
    } catch (error) {
      props.showPageLoader(false);
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const redirectToErrorScreen = (error) => {
    props.showPageLoader(false);
    props.history.push("/error");
  };

  const checkIfAllDataFilled = () => {
    if (docS3Key && isAgreementAccepted) {
      return true;
    }
    return false;
  };

  const onTermsOfUseClick = (event) => {
    event.preventDefault();
    setShowEula(true);
  };

  const onTermsOfUseAccept = () => {
    setShowEula(false);
    setAgreementAccepted(true);
  };

  return (
    <React.Fragment>
      {redirectToHoldPayment && (
        <Redirect
          to={{
            pathname: "/hold-payment",
          }}
        />
      )}

      <div className="upload-page-wrapper aligning-middle">
        <h2>Pre Check-In</h2>

        <div className="upload-form-wrap">
          <span className="upload-title">
            Please make sure your photo clearly shows your face. This is a
            secure server and we keep all of your data safe.
          </span>

          <div className="upload-doc-wrapper">
            <UploadGuestPhoto
              alreadyUploadedImage={alreadyUploadedImageUrl}
              onImageUploadToS3={onImageUploadToS3}
              s3Key="photo.png"
              level="protected"
              disabled={false}
              setS3Key={setDocS3key}
              validationCounter={validationCounter}
              setValidationCounter={setValidationCounter}
            />
          </div>

          <div className="checkbox-wrapper">
            <AppCheckBox
              isChecked={isAgreementAccepted}
              toggleChecked={setAgreementAccepted}
            />
            <div className="about-agreement">
              I agree to the{" "}
              <button className="user-terms" onClick={onTermsOfUseClick}>
                terms of use
              </button>{" "}
              and{" "}
              <button className="user-terms" onClick={onTermsOfUseClick}>
                booking agreement
              </button>
            </div>
          </div>

          <button onClick={handleSubmit} className="btn next-btn">
            Next
          </button>

          <div style={{ paddingTop: "20px" }}>
            <MessageButton />
          </div>

          <Eula
            onAccept={() => onTermsOfUseAccept()}
            onDismiss={() => setShowEula(false)}
            text={termsAndConditions}
            activeClass={showEula && "active"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  showPageLoader,
};

UploadPhoto.propTypes = {
  showPageLoader: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(UploadPhoto);
