import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./styles.scss";
import {
  getEmail,
  getPhoneNumber,
  getPropertyId,
  getReservationId,
  removeItemFromLocalStorage,
} from "../../common/utils/localStorageUtils";
import {
  getReservationsByPhoneNumberService,
  postUpdateReservation,
} from "../Login/service";
import { LOGIN_SERVICES } from "../Login/constants";
import { MessageButton } from "../../components/MessageButton";

export const CheckInSuccess = (props) => {
  const [showButton, setShowButton] = useState(false);
  const [reservationId, setReservationId] = useState(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });
    checkShowButton();
  }, []);

  const checkShowButton = async () => {
    const propertyId = await getPropertyId();
    const encryptMobileNo = await getPhoneNumber();
    const list = await getReservationsByPhoneNumber(
      atob(encryptMobileNo),
      propertyId
    );

    if (list && list.length) {
      setShowButton(true);
    } else {
      //cognito sign-out
      Auth.signOut();
      //clear local storage variables
      await removeItemFromLocalStorage("email");
      await removeItemFromLocalStorage("approvedEmails");
      await removeItemFromLocalStorage("accessToken");
      await removeItemFromLocalStorage("approvedPhones");
      await removeItemFromLocalStorage("timerStartsTime");
    }
  };

  useEffect(() => {
    (async () => {
      setReservationId(await getReservationId());
      if (reservationId) {
        const email = await getEmail();
        await postUpdateReservation(
          LOGIN_SERVICES.postUpdateReservation.apiEndpoint,
          {
            email,
            reservationId,
          }
        ).catch((e) => console.log(e));
      }
    })();
  }, [reservationId]);

  const getReservationsByPhoneNumber = async (phoneNumber, propertyId) => {
    try {
      const response = await getReservationsByPhoneNumberService(
        LOGIN_SERVICES.getReservationsByPhoneNumber.apiEndpoint,
        {
          phoneNumber,
          propertyId,
        }
      );
      if (response) {
        return response;
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const goToLogin = async () => {
    const propertyId = await getPropertyId();
    const encryptMobileNo = await getPhoneNumber();
    const url = `/?phone=${encryptMobileNo}&reservationId=${reservationId}&propertyId=${propertyId}`;
    props.history.push(url);
  };

  const redirectToErrorScreen = (error) => {
    props.history.push("/error");
  };

  return (
    <div className="success-checkin-wrap">
      <div className="success-checkin">
        <h4>
          Congratulations!
          <span>Pre Check-in successful.</span>
        </h4>
        <p>
          Please allow us to process the documents you have submitted. Your
          digital key will be sent to your mobile on day of arrival.
        </p>
        <br />
        {showButton && (
          <button onClick={goToLogin} className="btn black-btn">
            Next Pre Check-in
          </button>
        )}
      </div>

      <div style={{ paddingTop: "100px" }}>
        <MessageButton />
      </div>
    </div>
  );
};
