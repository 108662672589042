import { Auth } from "aws-amplify";

export const getJwtToken = async () => {
	try {
		return (await Auth.currentSession()).getIdToken().getJwtToken();
	} catch (error) {}
};

export const getCognitoIdentityId = async () => {
	return (await Auth.currentUserCredentials()).identityId;
};

export const getCognitoUserPoolId = async () => {
	try {
		return (await Auth.currentAuthenticatedUser()).username;
	} catch (error) {}
};
