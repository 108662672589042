export const ERROR_INFO = {
  title: "Something went wrong.",
  subTitle: "We were unable to process the request",
};

export const RESERVATION_NOT_FOUND_INFO = {
  title: "Reservation not found or completed",
  subTitle:
    "We were unable to find a valid reservation to proceed with the pre check-in.",
};
