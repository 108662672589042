import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";

export const getAttachmentToDownloadService = async (apiEndpoint, attachmentId) => {
  const token = await getJwtToken();
  console.log(token);
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        attachmentId,
      }),
    })
      .POST()
      .then((response) => {
        resolve(JSON.parse(response.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
};

