import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import GenericErrorImg from "src/app/assets/images/closed-lock-icon.svg";
import { ERROR_INFO } from "./constants";
import { MessageButton } from "../../components/MessageButton";
import { getPropertyDataService } from "./service";
import { getPropertyId } from "src/app/common/utils/localStorageUtils";
import { showPageLoader } from "../Middleware/actionCreators";

export const SecurityBlock = (props) => {
  const history = useHistory();
  const [propertyData, setPropertyData] = useState(null);

  useEffect(() => {
    Promise.all([getPropertyData()]).then(() => {
      showPageLoader(false);
    });
  }, []);

  const goBackToPreviousScreen = () => {
    history.push("/login");
  };

  const getPropertyData = async () => {
    showPageLoader(true);
    try {
      const data = await getPropertyDataService("reservation/getPropertyData", {
        propertyId: await getPropertyId(),
      });

      setPropertyData(data);

      return data;
    } catch (error) {
      showPageLoader(false);
    }
  };

  return (
    <div className="errorState-wrapper">
      <div className="error-state">
        <div className="error-img">
          <img src={GenericErrorImg} alt="error" />
        </div>
        <div className="errorState-text">
          {ERROR_INFO.title}
          <span>{ERROR_INFO.subTitle}</span>
          <span>{propertyData ? `Phone: ${propertyData.phone}` : null}</span>
        </div>
        <button className="retry-btn" onClick={goBackToPreviousScreen}>
          Retry
        </button>
        <div style={{ paddingTop: "100px" }}>
          <MessageButton />
        </div>
      </div>
    </div>
  );
};
