import { getFormattedDate } from "../../common/utils/dateUtils";
import { DATE_FORMATS } from "../../common/constants/AppConstants";

export const parseReservationDetails = (reservation) => {
  const { checkout_date, checkin_date } = reservation;

  if (reservation) {
    return {
      ...reservation,
      checkin_date: getFormattedDate(checkin_date, DATE_FORMATS.dayTimeFormat),
      checkout_date: getFormattedDate(
        checkout_date,
        DATE_FORMATS.dayTimeFormat
      ),
    };
  }
};
