export const UPLOAD_DOCUMENT_SERVICES = {
	getDocumentTypes: {
		apiEndpoint: "reservation/getIdentityDocTypes",
	},
	getTermsAndConditions: {
		apiEndpoint: "getTermsAndConditions",
	},
	updateIdentityDocument: {
		apiEndpoint: "reservation/updateGuestUserReservationIdentityDocuments",
	},
	getUserUploadedDocuments: {
		apiEndpoint: "reservation/getGuestUserReservationIdentityDocuments",
	},
	updateGuestUserReservationPhoto: {
		apiEndpoint: "reservation/updateGuestUserReservationPhoto",
	},
};
