import React, { useEffect, useState } from "react";
import { AppDropdown } from "../../components/AppDropdown";
import "./styles.scss";
import { UPLOAD_DOCUMENT_SERVICES } from "./constants";
import {
  getIdentityDocumentTypesService,
  updateIdentityDocumentService,
  getTermsAndConditionsService,
  getUserUploadedDocumentService,
} from "./service";
import { UploadImage } from "../../components/UploadImage";
import { AppCheckBox } from "../../components/AppCheckBox";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showPageLoader } from "../Middleware/actionCreators";
import { getCognitoIdentityId } from "../../common/utils/sessionUtils";
import { Eula } from "../../components/Eula";
import {
  createOrUpdateWebCheckInStatusService,
  fetchS3ImageUrlByS3Key,
} from "../../common/services/AppServices";
import { WEB_CHECKIN_STATUS_TYPES } from "../../common/constants/AppConstants";
import { Redirect } from "react-router";
import {
  getPropertyId,
  getReservationId,
  getUserId,
} from "../../common/utils/localStorageUtils";
import { MessageButton } from "../../components/MessageButton";

const UploadDocument = (props) => {
  const [docTypes, setDocTypes] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [isAgreementAccepted, setAgreementAccepted] = useState(false);
  const [docS3Key, setDocS3key] = useState(false);
  const [showEula, setShowEula] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [redirectToNextPage, setRedirectToNextPage] = useState(false);
  const [alreadyUploadedImageUrl, setAlreadyUploadedImageUrl] = useState(null);
  const [validationCounter, setValidationCounter] = useState(1);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });
    props.showPageLoader(true);
    Promise.all([
      getIdentityDocumentTypes(),
      getUserUploadedDocument(),
      getTermsAndConditions(),
    ]).then(() => {
      props.showPageLoader(false);
    });
  }, []);

  const getIdentityDocumentTypes = async () => {
    const { apiEndpoint } = UPLOAD_DOCUMENT_SERVICES.getDocumentTypes;
    try {
      const response = await getIdentityDocumentTypesService(apiEndpoint);
      let documentTypes = [];
      if (response && response.length > 0) {
        response.forEach((element) => {
          if (
            element.value === "Driver License" ||
            element.value === "Passport"
          ) {
            documentTypes = [...documentTypes, element];
          }
        });
        setDocTypes(documentTypes);
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const getTermsAndConditions = async () => {
    const { apiEndpoint } = UPLOAD_DOCUMENT_SERVICES.getTermsAndConditions;
    try {
      const propertyId = await getPropertyId();
      const response = await getTermsAndConditionsService(apiEndpoint, {
        propertyId,
      });
      response && setTermsAndConditions(response);
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const getUserUploadedDocument = async () => {
    const { apiEndpoint } = UPLOAD_DOCUMENT_SERVICES.getUserUploadedDocuments;
    try {
      const reservationId = await getReservationId();
      const userId = await getUserId();
      const response = await getUserUploadedDocumentService(apiEndpoint, {
        reservationId,
        userId,
      });

      if (response && response.length > 0) {
        const [
          { identity_doc_s3_key, identity_doc_name, cognito_identity_id },
        ] = response;
        setSelectedDocType(identity_doc_name);
        setDocS3key(identity_doc_s3_key);
        const imageUrl = await fetchS3ImageUrlByS3Key(
          identity_doc_s3_key,
          cognito_identity_id
        );
        setAlreadyUploadedImageUrl(imageUrl);
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const getSelectedDocumentTypeId = () => {
    return (
      docTypes &&
      docTypes.find((doc) => {
        return doc.name === selectedDocType;
      })
    );
  };

  const onImageUploadToS3 = async (s3Key) => {
    const doc = getSelectedDocumentTypeId();

    setDocS3key(s3Key);

    for (let i = 1; i < 10; i++) {
      let response = await updateIdentityDocument(s3Key, doc.id);
      if (response === "Identity documents updated successfully.") {
        i = 10;
      }
    }
  };

  const updateIdentityDocument = async (s3Key, docId) => {
    const { apiEndpoint } = UPLOAD_DOCUMENT_SERVICES.updateIdentityDocument;
    const cognitoIdentityId = await getCognitoIdentityId();

    try {
      const response = await updateIdentityDocumentService(apiEndpoint, {
        s3Key,
        docId,
        cognitoIdentityId,
      });
      return response;
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(true);
    props.showPageLoader(true);

    try {
      const isValidated = checkIfAllDataFilled();

      if (isValidated && alreadyUploadedImageUrl && docS3Key) {
        const doc = getSelectedDocumentTypeId();
        await updateIdentityDocument(docS3Key, doc.id);

        const response = await updateDocUploadedInStatus();
        if (response && response === "Status updated successfully.") {
          setRedirectToNextPage(true);
        }
      }
    } catch (error) {
      props.showPageLoader(false);
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const updateDocUploadedInStatus = async () => {
    props.showPageLoader(true);

    try {
      const response = await createOrUpdateWebCheckInStatusService(
        WEB_CHECKIN_STATUS_TYPES.DOC_UPLOADED
      );
      return response;
    } catch (error) {
      props.showPageLoader(false);
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const redirectToErrorScreen = (error) => {
    props.showPageLoader(false);
    props.history.push("/error");
  };

  const checkIfAllDataFilled = () => {
    if (selectedDocType && docS3Key && isAgreementAccepted) {
      return true;
    }
    return false;
  };

  const onTermsOfUseClick = (event) => {
    event.preventDefault();
    setShowEula(true);
  };

  const onTermsOfUseAccept = () => {
    setShowEula(false);
    setAgreementAccepted(true);
  };

  return (
    <React.Fragment>
      {redirectToNextPage && (
        <Redirect
          to={{
            pathname: "/upload-photo",
          }}
        />
      )}

      <div className="upload-page-wrapper aligning-middle">
        <h2>Pre Check-In</h2>

        <div className="upload-form-wrap">
          <span className="upload-title">
            Select Id type of the credit card holder
          </span>
          <AppDropdown
            onSelect={setSelectedDocType}
            config={docTypes}
            selectedValue={selectedDocType}
          />

          <div className="upload-doc-wrapper">
            <UploadImage
              alreadyUploadedImage={alreadyUploadedImageUrl}
              onImageUploadToS3={onImageUploadToS3}
              s3Key="document.png"
              level="protected"
              disabled={!selectedDocType}
              setS3Key={setDocS3key}
              validationCounter={validationCounter}
              setValidationCounter={setValidationCounter}
            />
          </div>

          <div className="checkbox-wrapper">
            <AppCheckBox
              isChecked={isAgreementAccepted}
              toggleChecked={setAgreementAccepted}
            />
            <div className="about-agreement">
              I agree to the{" "}
              <button className="user-terms" onClick={onTermsOfUseClick}>
                terms of use
              </button>{" "}
              and{" "}
              <button className="user-terms" onClick={onTermsOfUseClick}>
                booking agreement
              </button>
            </div>
          </div>

          <button onClick={handleSubmit} className="btn next-btn">
            Next
          </button>

          <div style={{ paddingTop: "20px" }}>
            <MessageButton />
          </div>

          <Eula
            onAccept={() => onTermsOfUseAccept()}
            onDismiss={() => setShowEula(false)}
            text={termsAndConditions}
            activeClass={showEula && "active"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  showPageLoader,
};

UploadDocument.propTypes = {
  showPageLoader: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(UploadDocument);
