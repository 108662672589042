import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";
import AppRoutes from "../AppRoutes";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppWrapper } from "../AppWrapper";
import {
  setItemIntoLocalStoarge,
  getItemFromLocalStorage,
  getPhoneNumber,
} from "../../common/utils/localStorageUtils";
import { getCognitoUserPoolId } from "../../common/utils/sessionUtils";
import {
  LOCAL_STORAGE_KEYS,
  Status_And_Route_Mapping,
} from "../../common/constants/AppConstants";
import { getUserDetailsService, getWebCheckInStatusService } from "./service";
import { MIDDLEWARE_SERVICES } from "./constants";

const Middleware = (props) => {
  var history = useHistory();
  var location = useLocation();
  var params = queryString.parse(location.search);
  const [isLoading, setLoading] = useState();

  let currentPathname = null;
  let currentSearch = null;
  useEffect(() => {
    props.history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== currentPathname ||
          newLocation.search !== currentSearch
        ) {
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;

          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else {
        history.go(1);
      }
    });
  }, []);

  useEffect(() => {
    performInitialActions();
  }, []);

  const performInitialActions = async () => {
    setLoading(true);
    await saveDataIntoLocalStorage(params);
    await getUserDetails();
    setLoading(false);
  };

  const getUserDetails = async () => {
    const { apiEndpoint } = MIDDLEWARE_SERVICES.getUser;

    try {
      const phoneNumber = await getPhoneNumber();
      const response = await getUserDetailsService(apiEndpoint, {
        phoneNumber: phoneNumber
          ? atob(phoneNumber)
          : process.env.REACT_APP_DEFAULT_PHONE_NO,
      });

      if (response && response.user) {
        await setItemIntoLocalStoarge(
          LOCAL_STORAGE_KEYS.userId,
          response.user.id
        );
      }
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const saveDataIntoLocalStorage = async (queryParams) => {
    const data = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.queryParams);

    if (!data) {
      const defaultParams = {
        reservationId: 293,
        phone: process.env.REACT_APP_DEFAULT_PHONE_NO,
        propertyId: "2",
      };
      const params = Object.keys(queryParams).length
        ? queryParams
        : defaultParams;
      setItemIntoLocalStoarge(
        LOCAL_STORAGE_KEYS.queryParams,
        JSON.stringify(params)
      );
    }
    if (
      JSON.parse(data) !== queryParams &&
      Object.keys(queryParams).length !== 0
    ) {
      await setItemIntoLocalStoarge(
        LOCAL_STORAGE_KEYS.queryParams,
        JSON.stringify(queryParams)
      );
    }
  };

  const redirectToErrorScreen = (error) => {
    console.log("Error occured at Middleware:", error);
    history.push("/error");
  };

  const { showBlurredBackground, toShowPageLoader } = props;
  return (
    <AppWrapper
      showLoader={toShowPageLoader || isLoading}
      showBlurredBackground={showBlurredBackground}
    >
      {!isLoading && <AppRoutes />}
    </AppWrapper>
  );
};

const mapStateToProps = (state) => {
  const { toShowPageLoader, showBlurredBackground } = state.middleware;
  return {
    toShowPageLoader,
    showBlurredBackground,
  };
};

export default connect(mapStateToProps, null)(withRouter(Middleware));
