import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import CheckIn from "../CheckIn";
import Login from "../Login";
import VerifyOTP from "../VerfiyOTP";
import { NotFound } from "../NotFound";
import { Redirect } from "react-router";
import { GenericError } from "../GenericError";
import { ReservationNotFound } from "../ReservationNotFound";
import UploadDocument from "../UploadDocument";
import UploadPhoto from "../UploadPhoto";
import HoldPayment from "../HoldPayment";
import ExtraServices from "../ExtraServices";
import { CheckInSuccess } from "../CheckInSuccess";
import Attachments from "../Attachments";
import { SecurityBlock } from "../SecurityBlock";

const AppRoutes = () => {
  return (
    <Switch>
      {/* By Default: This is a public Route */}
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Route path="/login" component={Login} />

      <Route path="/attachments" component={Attachments} />

      <Route exact path="/error" component={GenericError} />

      <Route exact path="/security-block" component={SecurityBlock} />

      <Route
        exact
        path="/reservation-not-found"
        component={ReservationNotFound}
      />
      <Route path="/verify-otp" component={VerifyOTP} />

      <Route exact path="/check-in" component={CheckIn} />
      <Route exact path="/upload-document" component={UploadDocument} />
      <Route exact path="/upload-photo" component={UploadPhoto} />
      <Route exact path="/hold-payment" component={HoldPayment} />
      <Route exact path="/extra-services" component={ExtraServices} />
      <Route exact path="/check-in-success" component={CheckInSuccess} />

      {/* Generic error page */}
      <Route path="/error" component={GenericError} />

      {/* default route: if the requested route doesn't match with any of the above routes then this page will be displayed */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(AppRoutes);
