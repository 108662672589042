import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Redirect, useLocation } from "react-router";
import { useStore } from "react-redux";
import { MessageButton } from "../../components/MessageButton";
import { MIDDLEWARE_SERVICES } from "../Middleware/constants";
import { getWebCheckInStatusService } from "../Middleware/service";
import {
  TIMER_DURATION,
  ERROR_TYPES,
  API_ERROR_TYPES,
  TIME_PERIOD_TO_ENABLE_RESEND,
} from "./constants";
import { ErrorIncorrectInfo } from "../../components/ErrorIncorrectInfo";
import {
  USER_PASSWORD,
  Status_And_Route_Mapping,
} from "../../common/constants/AppConstants";
import PropTypes from "prop-types";
import { AppOtpInput } from "../../components/AppOtpInput";
import { getFormattedTime, showConfirmationAlert } from "./utils";
import { connect } from "react-redux";
import {
  showPageLoader,
  setBlurredBackground,
} from "../Middleware/actionCreators";
import {
  setItemIntoLocalStoarge,
  getItemFromLocalStorage,
  getPhoneNumber,
  getEmail,
} from "../../common/utils/localStorageUtils";
import { CONSTANTS } from "../../common/constants";
import { createOrUpdateWebCheckInStatusService } from "../../common/services/AppServices";
import { WEB_CHECKIN_STATUS_TYPES } from "../../common/constants/AppConstants";
import "./styles.scss";

const VerifyOTP = (props) => {
  let location = useLocation();
  const store = useStore();

  const [code, setCode] = useState("");
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cognitoUser, setCognitoUser] = useState(
    store.getState().login.cognitoUser
  );

  const [timer, setTimer] = useState(TIMER_DURATION);
  const [isTimerActive, setTimerActive] = useState(true);
  const [isCodeMisMatchError, setCodeMisMatchError] = useState(false);
  const [isResendingCode, setResendingCode] = useState(false);
  const [isCodeExpiredError, setCodeExpiredError] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });

    const phoneNumber =
      location && location.state && location.state.phoneNumber;

    if (
      !location.state ||
      !location.state.phoneNumber ||
      !store.getState().login.cognitoUser
    ) {
      props.setBlurredBackground(true);
      showConfirmationAlert(onOkClick);
    }

    setPhoneNumber(phoneNumber);
    saveTimeBeforeCounterStarts();
    return () => {};
  }, []);

  const onOkClick = () => {
    props.history.push("/login");
    props.setBlurredBackground(false);
  };

  useEffect(() => {
    var interval;

    if (timer <= TIME_PERIOD_TO_ENABLE_RESEND) {
      setTimerActive(false);
    }

    if (timer > 0) {
      runTimerToEnableResendCodeOption().then((inter) => {
        interval = inter;
      });
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const saveTimeBeforeCounterStarts = async () => {
    const date = Date.now() + TIMER_DURATION * 1000;
    await setItemIntoLocalStoarge(
      CONSTANTS.APP_CONSTANTS.LOCAL_STORAGE_KEYS.timerStartsTime,
      date
    );
  };

  const resetTimer = async () => {
    setTimer(TIMER_DURATION);
    await saveTimeBeforeCounterStarts();
    setTimerActive(true);
  };

  const runTimerToEnableResendCodeOption = async () => {
    const time = getItemFromLocalStorage(
      CONSTANTS.APP_CONSTANTS.LOCAL_STORAGE_KEYS.timerStartsTime
    );

    return setInterval(() => {
      const timeLeft = Math.floor(time - Date.now()) / 1000;
      if (timeLeft >= -1) {
        setTimer(Math.ceil(timeLeft));
      }
    }, 1000);
  };

  const onVerifyCodeClick = () => {
    props.showPageLoader(true);
    confirmSignIn(cognitoUser, code);
  };

  const confirmSignIn = async (cognitoUser, code) => {
    try {
      await Auth.sendCustomChallengeAnswer(cognitoUser, code);
      const currentSession = await Auth.currentSession();

      currentSession &&
        setItemIntoLocalStoarge(
          CONSTANTS.APP_CONSTANTS.LOCAL_STORAGE_KEYS.accessToken,
          currentSession.idToken.jwtToken
        );

      onCodeVerifiedSuccess();
    } catch (error) {
      onCodeVerifiedFailed(error);
    }
  };

  const resendConfirmationCode = async () => {
    setResendingCode(true);
    try {
      const user = await Auth.signIn({
        username: phoneNumber,
        password: USER_PASSWORD,
      });
      setCognitoUser(user);
      resetTimer();
    } catch (error) {
      onCodeVerifiedFailed(error);
    } finally {
      setResendingCode(false);
    }
  };

  const onCodeVerifiedSuccess = async () => {
    const { apiEndpoint } = MIDDLEWARE_SERVICES.getWebCheckInStatus;
    try {
      const result = await getWebCheckInStatusService(apiEndpoint);
      if (result && result.check_in_status) {
        const phone = await getPhoneNumber();
        const email = await getEmail();

        let approvedPhones = JSON.parse(
          localStorage.getItem("approvedPhones") || "[]"
        );
        if (approvedPhones) {
          const temp = [...approvedPhones, phone];
          approvedPhones = temp;
          localStorage.setItem(
            "approvedPhones",
            JSON.stringify(approvedPhones)
          );
        }

        let approvedEmails = JSON.parse(
          localStorage.getItem("approvedEmails") || "[]"
        );
        if (approvedEmails) {
          const temp = [...approvedEmails, email];
          approvedEmails = temp;
          localStorage.setItem(
            "approvedEmails",
            JSON.stringify(approvedEmails)
          );
        }

        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          email: email,
        });

        setRedirectTo(Status_And_Route_Mapping[result.check_in_status]);
      } else {
        await updateWebCheckInStatus();
        setRedirectTo(Status_And_Route_Mapping["Logged-in"]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      props.showPageLoader(false);
      setIsCodeVerified(true);
    }
  };

  const updateWebCheckInStatus = async () => {
    try {
      await createOrUpdateWebCheckInStatusService(
        WEB_CHECKIN_STATUS_TYPES.LOGGED_IN
      );
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const onCodeVerifiedFailed = (error) => {
    props.showPageLoader(false);
    setCode("");

    if (error.code === API_ERROR_TYPES.CodeMismatchException) {
      setCodeMisMatchError(true);
      return;
    }
    if (error.code === API_ERROR_TYPES.NOTAuthorizedException) {
      setCodeExpiredError(true);
      return;
    }

    setIsCodeVerified(false);
    setCodeMisMatchError(true);
  };

  const redirectToErrorScreen = (error) => {
    props.history.push("/error");
  };

  const checkForValidCode = () => {
    return code.length < 6;
  };

  return (
    <React.Fragment>
      {isCodeVerified && (
        <Redirect
          to={{
            pathname: redirectTo,
          }}
        />
      )}

      <div className="otp-page-wrapper">
        <AppOtpInput setOtp={setCode} otp={code} />
        <div className="otp-session-wrap">
          <div
            style={{
              paddingBottom: "30px",
            }}
          >
            <div className="time-remaining">
              Time Remaining:{" "}
              <span className="countdown-remaining">
                {getFormattedTime(timer)}
              </span>{" "}
              mins
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <button
              className={!isTimerActive ? "resend-btn" : "resend-btn-disabled"}
              onClick={resendConfirmationCode}
              disabled={isTimerActive || isResendingCode}
            >
              Resend OTP
            </button>

            <button
              onClick={onVerifyCodeClick}
              disabled={!checkForValidCode}
              className="btn black-btn"
            >
              Next
            </button>
          </div>
        </div>
        <div style={{ paddingTop: "100px" }}>
          <MessageButton />
        </div>
      </div>

      {(isCodeMisMatchError || isCodeExpiredError) && (
        <ErrorIncorrectInfo
          errorMessage={
            isCodeMisMatchError
              ? ERROR_TYPES.incorrectOTP
              : ERROR_TYPES.expiredOTP
          }
        />
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  showPageLoader,
  setBlurredBackground,
};

VerifyOTP.propTypes = {
  showPageLoader: PropTypes.func,
  setBlurredBackground: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(VerifyOTP);
