import { Amplify } from "aws-amplify";
import { CONSTANTS } from "./common/constants";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Middleware from "../app/pages/Middleware";

const App = () => {
  Amplify.configure(CONSTANTS.AWS_CONSTANTS.AWS_CONFIG.AWS);

  return (
    <Provider store={store}>
      <Router>
        <Route path="/" component={Middleware} />
      </Router>
    </Provider>
  );
};

export default App;
