export const CHECK_IN_SERVICES = {
  getUserReservationDetails: {
    key: "reservation",
    apiEndpoint: "getGuestUserReservationDetails",
  },
  getStripePublicToken: {
    key: "stripe",
    apiEndpoint: "stripe/getStripePublicToken",
  },
  getHoldAmountByProperty: {
    key: "stripe",
    apiEndpoint: "stripe/getHoldAmountByProperty",
  },
  getUserUploadedDocuments: {
    apiEndpoint: "reservation/getGuestUserReservationIdentityDocuments",
  },
  getHasHold: {
    apiEndpoint: "stripe/getHasHold",
  },
};
