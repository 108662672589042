import AxiosBuilder from "../../common/services/AxiosBuilder";
import { getJwtToken } from "../../common/utils/sessionUtils";
import {
  getUserId,
  getReservationId,
} from "../../common/utils/localStorageUtils";

export const getIdentityDocumentTypesService = async (apiEndpoint) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .GET()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTermsAndConditionsService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams({ propertyId: params.propertyId })
      .GET()
      .then((response) => {
        resolve(JSON.parse(response.data));
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// getting user's uploaded documents
export const getUserUploadedDocumentService = async (apiEndpoint, params) => {
  const token = await getJwtToken();
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams(params)
      .GET()
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateIdentityDocumentService = async (apiEndpoint, data) => {
  const token = await getJwtToken();
  const reservationId = await getReservationId();
  const userId = await getUserId();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId,
        userId,
        identityDocS3Key: data.s3Key,
        identityDocId: data.docId,
        cognitoIdentityId: data.cognitoIdentityId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateGuestUserReservationPhotoService = async (apiEndpoint, data) => {
  const token = await getJwtToken();
  const reservationId = await getReservationId();
  const userId = await getUserId();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId,
        userId,
        guestPhotoS3Key: data.s3Key,
        cognitoIdentityId: data.cognitoIdentityId,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
