import React from "react";

export const ErrorIcon = () => {
	return (
		<span className="error-icon">
			<svg
				width="14px"
				height="16px"
				viewBox="0 0 14 16"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
			>
				<title>Group</title>
				<g
					id="Page-1"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
				>
					<g
						id="7-TuckHotel-UPLOAD-ERROR-V2"
						transform="translate(-100.000000, -329.000000)"
						fill="#F35353"
						fill-rule="nonzero"
					>
						<g id="Group-6" transform="translate(50.000000, 206.000000)">
							<g id="Group-4" transform="translate(0.000000, 98.000000)">
								<g id="Group-7" transform="translate(50.000000, 5.000000)">
									<g
										id="noun_Error_911854"
										transform="translate(0.000000, 20.000000)"
									>
										<g id="Group" transform="translate(0.000000, 0.081582)">
											<path
												d="M7.00000003,0.0589611628 C3.13593651,0.0589611628 3.25580913e-08,3.19492209 3.25580913e-08,7.05896116 C3.25580913e-08,10.9230116 3.13593651,14.0589612 7.00000003,14.0589612 C10.8640635,14.0589612 14,10.9230116 14,7.05896116 C14,3.19492209 10.8640635,0.0589611628 7.00000003,0.0589611628 Z M7,0.384542558 C10.6881063,0.384542558 13.6744186,3.3708793 13.6744186,7.05896279 C13.6744186,10.7470512 10.6881063,13.7333814 7,13.7333814 C3.31189372,13.7333814 0.325581428,10.7470512 0.325581428,7.05896279 C0.325581428,3.3708793 3.31189372,0.384542558 7,0.384542558 Z M6.02325581,2.17524023 L6.34883721,9.98919535 L7.65116279,9.98919535 L7.97674419,2.17524023 L6.02325581,2.17524023 Z M6.34883721,10.6403581 L6.34883721,11.9426837 L7.65116279,11.9426837 L7.65116279,10.6403581 L6.34883721,10.6403581 Z"
												id="Shape"
											></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</span>
	);
};
