import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import "./styles.scss";
import GenericErrorImg from "src/app/assets/images/error-image.png";
import { RESERVATION_NOT_FOUND_INFO } from "./constants";
import { MessageButton } from "../../components/MessageButton";
import { removeItemFromLocalStorage } from "../../common/utils/localStorageUtils";

export const ReservationNotFound = () => {
  useEffect(() => {
    removeLocalStorage();
  }, []);

  const removeLocalStorage = async () => {
    Auth.signOut();
    await removeItemFromLocalStorage("email");
    await removeItemFromLocalStorage("approvedEmails");
    await removeItemFromLocalStorage("accessToken");
    await removeItemFromLocalStorage("approvedPhones");
    await removeItemFromLocalStorage("timerStartsTime");
  };

  return (
    <div className="errorState-wrapper">
      <div className="error-state">
        <div className="error-img">
          <img src={GenericErrorImg} alt="error" />
        </div>
        <div className="errorState-text">
          {RESERVATION_NOT_FOUND_INFO.title}
          <span>{RESERVATION_NOT_FOUND_INFO.subTitle}</span>
        </div>

        <div style={{ paddingTop: "100px" }}>
          <MessageButton />
        </div>
      </div>
    </div>
  );
};
