import React, { useEffect, useState } from "react";
import moment from "moment";
import "./styles.scss";
import {
  getExtraChargesItemsService,
  getReservationDetailsService,
  createExtraChargeService,
  chargeService,
  getReservationDataService,
  updatePreCheckInService,
  sendNotificationService,
} from "./service";
import { CHECK_IN_SERVICES } from "./constants";
import { parseReservationDetails } from "./helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showPageLoader } from "../Middleware/actionCreators";
import { createOrUpdateWebCheckInStatusService } from "../../common/services/AppServices";
import { WEB_CHECKIN_STATUS_TYPES } from "../../common/constants/AppConstants";
import {
  getPropertyId,
  getReservationId,
} from "src/app/common/utils/localStorageUtils";
import { MessageButton } from "../../components/MessageButton";
import { AppCheckBox } from "../../components/AppCheckBox";
const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormGroupServices">
    <input
      className="FormRowInputServices"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const ExtraServices = (props) => {
  const [reservationDetails, setReservationDetails] = useState(null);
  const [reservationData, setReservationData] = useState(null);
  const [checked, setChecked] = useState([]);
  const [checkList, setChecklist] = useState([]);
  const [total, setTotal] = useState(0);
  const [reservationDays, setReservationDays] = useState(0);
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });

    initiate();
    return () => {};
  }, []);

  const initiate = async () => {
    props.showPageLoader(true);
    try {
      const propertyId = await getPropertyId();
      const reservationId = await getReservationId();
      const reservationDetailsResponse = await getReservationDetails();
      await getExtraChargesItems(reservationDetailsResponse.reservationDays);
      const { apiEndpoint } = CHECK_IN_SERVICES.getReservationData;
      const params = {
        reservationId: parseInt(reservationId),
        propertyId: parseInt(propertyId),
      };

      const response = await getReservationDataService(apiEndpoint, params);

      if (response) {
        setReservationData(response);
      }
    } catch (error) {
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const getReservationDetails = async () => {
    try {
      const reservationDetails = await getReservationDetailsService(
        CHECK_IN_SERVICES.getUserReservationDetails.apiEndpoint,
        {
          reservationId: await getReservationId(),
        }
      );

      setReservationDetails(parseReservationDetails(reservationDetails));

      const date1 = moment(reservationDetails.checkin_date);
      const date2 = moment(reservationDetails.checkout_date);
      const diff = date2.diff(date1, "days");
      setReservationDays(diff);

      reservationDetails.reservationDays = diff;
      return reservationDetails;
    } catch (error) {
      redirectToErrorScreen(error);
    }
  };

  const redirectToErrorScreen = (error) => {
    props.history.push("/error");
  };

  const getExtraChargesItems = async (days) => {
    const propertyId = await getPropertyId();
    try {
      const extraChargesItems = await getExtraChargesItemsService(
        CHECK_IN_SERVICES.getExtraChargesItems.apiEndpoint,
        propertyId
      );
      let availableExtraChargesItems = [];
      extraChargesItems.map((item) => {
        if (item.pre_checkin && item.active) {
          item.isChecked = false;
          availableExtraChargesItems.push(item);

          if (item.required) {
            if (item.charge_reservation_days) {
              item.quantity = days;
              item.total = parseFloat(item.amount * days);
            } else {
              item.quantity = 1;
              item.total = parseFloat(item.amount);
            }
            checked.push(item);
          }
        }
      });

      const totalAmount = calculateTotal(checked);
      setTotal(totalAmount);
      setChecklist(availableExtraChargesItems);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotal = (list) => {
    try {
      let total = 0;
      list.forEach((item) => {
        total = total + parseFloat(item.total);
      });
      return total.toFixed(2);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheck = (i, event) => {
    let updatedList = [...checked];

    if (event.target.checked) {
      if (checkList[i].charge_reservation_days) {
        checkList[i].quantity = reservationDays;
        checkList[i].total = parseFloat(checkList[i].amount * reservationDays);
      } else {
        checkList[i].quantity = 1;
        checkList[i].total = parseFloat(checkList[i].amount);
      }

      updatedList = [...checked, checkList[i]];
      const totalAmount = calculateTotal(updatedList);
      setTotal(totalAmount);
    } else {
      const index = checked.findIndex(
        (obj) => obj.description === checkList[i].description
      );
      updatedList.splice(index, 1);
      const totalAmount = calculateTotal(updatedList);
      setTotal(totalAmount);
    }
    setChecked(updatedList);
  };

  const handleExtraFieldChange = (i, event) => {
    const index = checked.findIndex(
      (obj) => obj.description === checkList[i].description
    );
    if (index !== -1) {
      checked[index].extra_field_value = event.target.value;
    }
  };

  const isChecked = (description) => {
    const index = checked.findIndex((obj) => obj.description === description);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleConfirmClick = async () => {
    props.showPageLoader(true);
    setProcessing(true);
    try {
      if (total > 0) {
        await createExtraCharge();
      } else {
        await confirmPreCheckIn();
      }
    } finally {
      setProcessing(false);
      props.showPageLoader(false);
    }
  };

  const updateStatus = async () => {
    props.showPageLoader(true);

    try {
      const response = await createOrUpdateWebCheckInStatusService(
        WEB_CHECKIN_STATUS_TYPES.WEB_CHECKIN
      );
      return response;
    } catch (error) {
      props.showPageLoader(false);
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const updatePreCheckInStatus = async () => {
    props.showPageLoader(true);

    try {
      const reservationId = await getReservationId();

      const { apiEndpoint } = CHECK_IN_SERVICES.updatePreCheckIn;
      const response = await updatePreCheckInService(apiEndpoint, {
        reservationId: reservationId,
        preCheckInStatus: true,
      });
      return response;
    } catch (error) {
      props.showPageLoader(false);
      redirectToErrorScreen(error);
    } finally {
      props.showPageLoader(false);
    }
  };

  const confirmPreCheckIn = async () => {
    try {
      const statusUpdate = await updateStatus();
      const preCheckInStatus = await updatePreCheckInStatus();
      if (statusUpdate && preCheckInStatus) {
        props.history.push("/check-in-success");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("Unable to complete pre check-in");
    } finally {
      props.showPageLoader(false);
    }
  };

  const createExtraCharge = async () => {
    try {
      props.showPageLoader(true);
      const { apiEndpoint } = CHECK_IN_SERVICES.createExtraCharge;
      const { apiEndpoint: sendNotificationEndpoint } =
        CHECK_IN_SERVICES.sendNotification;
      const propertyId = await getPropertyId();
      const reservationId = await getReservationId();
      const { pms_property_id } = reservationData;

      let chargeDescription = "";
      checked.forEach((item) => {
        if (chargeDescription === "") {
          chargeDescription = `${item.description}`;
        } else {
          chargeDescription = `${chargeDescription} / ${item.description}`;
        }
      });

      if (total > 0) {
        const paymentResult = await charge(chargeDescription, total);

        if (paymentResult.status === "succeeded") {
          props.showPageLoader(true);

          checked.forEach(async (item) => {
            await createExtraChargeService(apiEndpoint, {
              propertyId: propertyId,
              pmsPropertyId: pms_property_id,
              reservationId: reservationId,
              description: item.description,
              amount: item.total,
              quantity: item.quantity,
              paymentId: paymentResult.id,
              extraFieldValue: item.extra_field_value || null,
            });

            await sendNotificationService(sendNotificationEndpoint, {
              propertyId: propertyId,
              pmsPropertyId: pms_property_id,
              reservationId: reservationId,
              extraChargeItemId: item.id,
            });
          });

          await confirmPreCheckIn();
        } else {
          setError(true);
          setErrorMessage("Unable to complete the charge");
        }
      }
    } catch (error) {
      setError(true);
      setErrorMessage("Unable to complete the charge");
    } finally {
      props.showPageLoader(false);
    }
  };

  const charge = async (description, amount) => {
    props.showPageLoader(true);
    const { apiEndpoint } = CHECK_IN_SERVICES.charge;
    const { property_id, payment_method_id } = reservationData;

    try {
      const paymentResult = await chargeService(apiEndpoint, {
        propertyId: property_id,
        paymentMethodId: payment_method_id,
        description: description,
        amount: amount,
      });

      return paymentResult;
    } catch (error) {
      setError(true);
      setErrorMessage("Unable to complete the charge");
    } finally {
      props.showPageLoader(false);
    }
  };

  return (
    <div className="checkin-screen aligning-middle">
      {props.showPageLoader && reservationData && checkList && (
        <>
          <h2>Extras Services</h2>
          <span className="extra-services-subtitle">
            Include extra services to your reservation
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ paddingTop: "20px" }}>
              {reservationData &&
                checkList &&
                checkList.map((item, index) => (
                  <div key={item.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        paddingTop: "10px",
                      }}
                    >
                      {!item.required ? (
                        <label className="checkbox-container">
                          <input
                            value={item}
                            type="checkbox"
                            onChange={(e) => handleCheck(index, e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ) : (
                        <label className="checkbox-container">
                          <input
                            value={item}
                            type="checkbox"
                            checked={true}
                            readOnly={true}
                          />
                          <span className="checkmark"></span>
                        </label>
                      )}

                      <div>
                        {item.description + " "}
                        {item.charge_reservation_days
                          ? "($" +
                            item.amount * reservationDays +
                            " for the whole stay)"
                          : "($" + item.amount + ")"}
                      </div>
                    </div>
                    {isChecked(item.description) && item.extra_field ? (
                      <div
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <Field
                          label={item.extra_field_description}
                          id="name"
                          type="text"
                          placeholder={item.extra_field_description}
                          required
                          autoComplete="name"
                          onChange={(e) => {
                            handleExtraFieldChange(index, e);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                ))}
              <div style={{ paddingTop: "20px" }}>{`Total: $${total}`}</div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingTop: "20px",
                }}
              >
                <AppCheckBox
                  isChecked={isAgreementAccepted}
                  toggleChecked={setIsAgreementAccepted}
                />
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  I agree and accept this charge
                </div>
              </div>
            </div>

            {error && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  paddingTop: "25px",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "red",
                }}
              >
                {errorMessage}
              </div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "75.7%",
              }}
            >
              <button
                className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
                type="submit"
                onClick={handleConfirmClick}
                disabled={processing || !isAgreementAccepted}
              >
                {processing ? "Processing..." : "Complete Pre Check-In"}
              </button>
            </div>
          </div>

          <div style={{ paddingTop: "10px" }}>
            <MessageButton />
          </div>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  showPageLoader,
};

ExtraServices.propTypes = {
  showPageLoader: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ExtraServices);
