import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./styles.scss";

export const AppDropdown = ({ config, onSelect, selectedValue }) => {
  return (
    <div className="hotel-selectbox-wrap">
      <Dropdown
        value={selectedValue}
        options={config && config}
        onChange={(event) => {
          onSelect && onSelect(event.value);
        }}
        placeholder="Select an option"
      />
    </div>
  );
};

AppDropdown.propTypes = {
  config: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  selectedValue: PropTypes.any,
};

AppDropdown.defaultProps = {
  config: [
    { id: 1, value: "Driver License", label: "License" },
    { id: 2, value: "Driver License", label: "PAN Card" },
    { id: 3, value: "Driver License", label: "PAN Card" },
  ],
};
